

import { TextField } from '@mui/material';

export default function Date({ name, label, defaultDate, value, onChange, ...props }) {


    return (
        <TextField fullWidth
            id="date"
            label={label}
            type="date"
            // defaultValue={value ? null : "dd/mm/yyyy"}
            sx={{ minWidth: 220, backgroundColor: '#ffffff' }}
            value={value ? value : defaultDate}
            onChange={(newValue) => {
                onChange(newValue.target.value)
            }}
            InputLabelProps={{
                shrink: true,
            }}
        />
        // <LocalizationProvider dateAdapter={AdapterDayjs} >

        //     <DesktopDatePicker
        //         {...props}
        //         inputFormat="DD/MM/YYYY"
        //         value={value ? value : defaultDate}
        //         onChange={(newValue) => {
        //             onChange(dayjs(newValue?.$d).format('DD/MM/YYYY'))
        //         }}
        //         renderInput={(params) => <TextField
        //             sx={{
        //                 "& .MuiFormControl-root": { width: '100% !important' },
        //                 "& .MuiInputBase-root": { width: "100% !important" }
        //             }}
        //             // style={{ width: '100%' }}
        //             {...params}
        //             error={false}
        //         />}
        //     />


        // </LocalizationProvider>
    );
}
