export const types = {
    CREDENTIAL_PAGE: 'credentialPage',
    UNIQUE_CREDENTIAL_PAGE: 'uniqueCredentialPage',
    LANDING_PAGE: 'landingPage',
    WORKFLOW_PAGE: 'workflowPage',
    SIMPLE: 'simple',
    TITLE_WITH_BUTTON: 'titleWithButton',
    TITLE_WITH_BUTTONS: 'titleWithButtons',
    START_WORKFLOW: 'startWorkflow',
    STOP_WORKFLOW: 'stopWorkflow',
    FORM: 'form',
    CONNECTION_ACTIVE: 'connection_active'
}
export const config = {
    title: ' Trential SSI Demo',
    defaultPage: 'citizen-id',
    trentialSsiOfficialPageLink: 'https://www.trential.com/digital-credentials',
    theme: '#46F4C7',
    linksThatResetsTheWorkflow: ['citizen-id'],
    disclaimer: {
        text: 'This DEMO website is hosted by Trential. It is to demonstrate the usage of the Trential Wallet mobile app to accept, store & use verifiable credentials.',
        buttonText: 'Dismiss'
    },
    pages: {
        'citizen-id': {
            type: types.UNIQUE_CREDENTIAL_PAGE,
            title: 'Citizen ID',
            theme: '#46F4C7',
            themeShade: '#E6F1FE',
            themeSecondary: '#F9B712',
            flowScreen: {
                logo: '/svg/NationalIdBlue.svg',
                logoText: {
                    // primary: 'National Identification Agency',
                    // secondary: 'Republic of Wadiya',
                },
                headerBackgroundColor: '#E6F1FE',
                // headerRightLogo: '/svg/nationalIdHeader.svg',
                headerTextColor: '#ffffff',
                nav: ['About Us', 'Services', 'FAQ', 'Resources', 'Contact Us'],
                // showRestartDemoButton: false,
                // back: { title: "Home", link: '/' },
                title: "Get <span style=\"color:#46F4C7;\">Fayda</span> in your wallet",
                subtitle: 'Fayda can be used to prove your identity and avail a wide variety of services',
                startFlowButton: { title: "Get Fayda", color: "#46F4C7" },
                alreadyHaveCredentialButton: { title: "Already have Citizen ID", color: "#46F4C7" },
                // image: '/svg/NationalID.svg',
                action: {
                    title: 'Already have the Citizen ID?',
                    subtitle: 'Continue to explore...',
                    applications: [{
                        title: 'Doctor Registration',
                        description: 'You enrolled in Addis Ababa University for medical degree. And after graduating as a doctor , you wish to register with the Ethiopian Medical Association. You will be getting the following: \n - <b>Medical Student ID</b> \n - <b> Medical Degree</b> \n - <b> Doctor Registration</b>',
                        image: "/svg/doctorRegistration.svg",
                        buttonText: 'Register as a Doctor',
                        link: 'doctor-workflow'
                    }, {
                        title: 'International Travel',
                        description: 'You want to travel from Ethiopia to New Delhi. For it you would require\n - <b>COVID vaccination certificate</b>\n - <b>Flight tickets</b>',
                        image: "/svg/internationalTravel.svg",
                        buttonText: 'Plan my trip',
                        link: 'travel-workflow'
                    },
                        // {
                        //     title: 'Visit House Flow',
                        //     description: 'Use this credential to apply for a house',
                        //     link: '/travel-workflow'
                        // }, {
                        //     title: 'Get Vaccination Certificate',
                        //     description: 'Get your vaccination certificate online accepted all across the world',
                        //     link: '/vaccine'
                        // },
                        // {
                        //     title: 'Get Medical Degree',
                        //     description: 'Click the button below to avail a service like Degree, ID Card, etc',
                        //     link: '/education'
                        // }
                    ]
                }
            },

            flowProgress: {
                qrCodeScannerTitle: "Get Fayda in your wallet",
                title: "Thanks for connecting with us!",
                subtitle: '',
                stateToOpenOn: "Connection Active",
                steps: [{
                    label: 'Scan the QR using <b>Trential Wallet</b>.',
                    state: 'Proof Presentation Received'
                }, {
                    label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Verification Request</b> from <b>National Identification Agency</b>',
                    state: 'Proof Presentation Received'
                }, {
                    label: 'Fill the form with your details and share them',
                    state: 'Proof Request Verified'
                }, {
                    label: 'Under the <b>Pending Actions</b> section, you will now get a <b>Credential Offer</b> from <b>National Identification Agency</b>',
                    state: 'Credential Offer Sent'
                }, {
                    label: 'Check the details and <b>accept the offer</b> to get the <b>Citizen ID</b>',
                    state: ''
                }, {
                    label: 'You can now continue to the next step',
                    state: 'Credential Issued'
                }],

            },
            credentialInviteId: "citizen-id",
        },
        // 'home': {
        //     type: types.LANDING_PAGE,
        //     title: '',
        //     theme: '#1F86DC',
        //     themeShade: '#F0F6FA',
        //     welcomeScreen: {
        //         title: "Welcome!",
        //         subtitle: 'to a decentralised identity exchange system!',
        //         heroButtons: [{ question: '', title: "Lets Begin", link: '/travel-workflow' }],
        //         showPasswordLessLoginButton: false,
        //         heroImage: '/svg/credentialShare.svg',
        //         sections: [{
        //             title: 'What is it?',
        //             subtitle: '<span className="list">This demonstration is crafted to help you understand what a Self-Sovereign Identity (or SSI in short) is. After going through this, you will know the following​-\n 1. What is a digital identity\n 2. What are digital credentials​\n 3. How quickly you can connect with various organizations​\n 4. Your data belongs only to you​\n 5. How complex things can be done easily\n</span>',
        //             bulletsForSteps: false,

        //         }, {
        //             title: 'Some use cases...',
        //             subtitle: '<span className="list"> - Government Identity ​\n - Banking  ​\n - Education  ​\n - Healthcare  ​\n - Employment​ and many more…\n</span>',
        //             bulletsForSteps: false,

        //         },
        //         {
        //             type: types.TITLE_WITH_BUTTON,
        //             title: 'Try the demo yourself to see the power of SSI',
        //             subtitle: '​',
        //             button: { title: "Let's Begin", link: '/travel-workflow' },

        //         }, {
        //             type: types.TITLE_WITH_BUTTONS,
        //             title: 'Our Workflows',
        //             subtitle: '​',
        //             buttons: [{ title: "Get a house", link: '/house-workflow' }, { title: "Go travel", link: '/travel-workflow' }],

        //         }]

        //     },
        // },
        'travel-workflow': {
            type: types.WORKFLOW_PAGE,
            title: 'The Travel Experience',
            subtitle: 'Plan My Trip',
            theme: '#007CD7',
            themeShade: '#F0F6FA',
            pageToOpenUponTutorialStart: '/vaccine',
            successPage: {
                title: "Congratulations on booking your flight tickets",
                subtitle: 'We hope you enjoyed the demo and got to learn how to use Trential Wallet',
                buttons: [{ title: "Try House Loan Flow", link: [types.START_WORKFLOW, 'house-workflow'] },
                { title: "Back to Start", link: [types.STOP_WORKFLOW, 'home'] }],
                steps: [{
                    title: 'Share information from different documents',
                    icon: '/svg/successPageStepIcon1.svg'
                }, {
                    title: 'Verify your age range without sharing your exact date of birth',
                    icon: '/svg/successPageStepIcon4.svg'
                }, {
                    title: 'Life is easy using Trential Wallet',
                    icon: '/svg/successPageStepIcon3.svg'
                }],
                closingStatement: 'For any other use case, Reach out to us. <a href="https://www.trential.com/" target="_blank"><b>click here</b></a>',
                images: ['/png/vaccine.png', '/png/flight-booking.png'],
                links: ['/vaccine', '/flight-booking'],
            },
            stepperSteps: {
                steps: ['Get Vaccination Certificate', 'Book your Tickets'],
                links: ['/vaccine', '/flight-booking'],
                stepsCompleted: 0
            }
        },
        'vaccine': {
            type: types.CREDENTIAL_PAGE,
            title: 'KADISCO Healthcare',
            theme: '#404052',
            themeShade: '#e2f6ff',
            themeSecondary: '#FE7878',
            flowScreen: {
                logo: '/svg/kadiscoHealthcare.svg',
                nav: ['Hospitals', 'Treatments', 'Services', 'International Patients'],
                showRestartDemoButton: false,
                // back: { title: "Travel Flow", link: '/travel-workflow' },
                title: "<span style=\"color:#404052\"> Be safe, get yourself vaccinated Today!</span>",
                // subtitle: 'Travel made easy with digital wallet. Covid-19 guidelines from different government agencies have mandated every traveller to hold a Fully Vaccinated Certificate (VC).',
                startFlowButton: { title: "Get Covid Vaccination Certificate", color: "#46F4C7" },
                image: '/svg/Vaccine.svg',
                // jargon: {
                //     title: "Our fight against COVID",
                //     cards: [{
                //         title: "Covid-19 Symptoms",
                //         subtitle: 'Find more about the Covid-19 symptoms'
                //     }, {
                //         title: "Book a Test",
                //         subtitle: 'Book a test with your nearest collection point'
                //     }, {
                //         title: "Track your Report",
                //         subtitle: 'Track your reports by clicking here'
                //     },]
                // },
                action: {
                    title: 'Already have a Fully Vaccinated Certificate? Check our services which you can try now',
                    applications: [{
                        title: 'Flight tickets',
                        description: 'Book your flights online',
                        link: '/flight-booking'
                    }]
                }
            },
            flowProgress: {
                qrCodeScannerTitle: "Get Vaccination Certificate",
                title: "Thanks for connecting with us!",
                stateToOpenOn: 'Proof Request Sent',
                steps: [{
                    label: 'Scan the QR using <b>Trential Wallet</b>.',
                    state: 'Proof Presentation Received'
                }, {
                    label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Verification Request</b> from <b>Kadisco General Hospital</b>',
                    state: 'Proof Presentation Received'
                }, {
                    label: '<b>Select the credentials you want to share the requested information from using the dropdown and tap Share<b>',
                    info: ['You can share information in a single request using multiple credentials', 'In this request, only the fact that your age is greater than 18 is shared and not the actual year of birth'],
                    state: 'Proof Request Verified'
                }, {
                    label: 'Under the <b>Pending Actions</b> section, you will now get a <b>Credential Offer</b> from <b>Kadisco General Hospital</b>',
                    state: 'Credential Offer Sent'
                }, {
                    label: 'Check the details and <b>accept the offer</b> to get the <b>Vaccination Certificate</b>',
                    state: 'Credential Issued'
                }, {
                    label: 'You now have the Vaccination Certificate. Please proceed to the next step.',
                    state: 'Credential Issued'
                }],

            },
            congratulationsScreen: {
                title: 'Congratulations!',
                flexDirection: 'column',
                info: "<b>You have received your COVID Vaccination Certificate.</b><br> You can use it to verify your vaccination status as this document is mandatory for travel.",
                image: '/svg/vaccineCongratulations.svg'
            },
            credentialInviteId: "fayda-vaccine",
        },
        'flight-booking': {
            type: types.CREDENTIAL_PAGE,
            title: 'Ethiopian Airlines',
            theme: '#C11717',
            themeShade: '#FFF3F3',
            themeSecondary: '#21A7D6',
            flowScreen: {
                logo: '/svg/travelsLogo.svg',
                headerRightLogo: '/svg/travelsRightLogo.svg',
                headerBackgroundColor: '#C11717',
                headerTextColor: '#ffffff',
                nav: ['Hotels', 'Flights', 'Contact & Support'],
                navFontWeight: 400,
                showRestartDemoButton: false,
                // back: { title: "Home", link: '/' },
                customElements: {
                    welcomeScreen: "flightBooking1.js",
                    formTopScreen: "flightBooking2.js"
                },
                title: "Ethiopian Airlines",
                subtitle: 'Book your flights online to 50 countries across the world, from anywhere & anytime',
                startFlowButton: { title: "Book Ticket" },
                image: '/svg/Travel.svg',
                jargon: {
                    title: "",
                    cards: [{
                        title: "Covid-19 Guidelines",
                        subtitle: 'Find out more about covid-19 travel Guidlines'
                    }, {
                        title: "Explore Ethiopian Airlines Pass",
                        subtitle: 'Our Pass comes with a variety of benefits. Click here to explore'
                    }, {
                        title: "Discover Offers",
                        subtitle: 'We have slashed the prices for popular holiday destinations. Click here to view'
                    },]
                },
                // action: {
                //     title: '',
                //     subtitle: 'Already have House? Check our services which you can try now',
                //     applications: [{
                //         title: 'Visit House Flow',
                //         description: 'Use this credential to apply for a house',
                //         link: '/house-workflow'
                //     },]
                // }
            },
            flowProgress: {
                type: types.FORM,
                qrCodeScannerTitle: 'Autofill using Trential Wallet',
                qrCodeScannerTitle2: 'Get Flight Ticket',
                // title: "Thanks for connecting with us!",
                // subtitle: 'To get Vaccine Certificate, complete the following steps',
                stateToOpenOn: null,
                disableQrDialogOnThisState: 'Proof Presentation',
                formSteps: [{
                    label: 'Scan the QR using <b>Trential Wallet</b>.',
                    state: 'Proof Presentation Received'
                }, {
                    label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Verification Request</b> from <b>SpeedJet</b>',
                    state: 'Proof Presentation'
                }, {
                    label: 'Select the credentials you want to share the requested information from using the dropdown and tap <b>Share</b>',
                    info: ['You can share information in a single request using multiple credentials'],
                    state: 'Proof Presentation'
                }],
                steps: [
                    {
                        label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Credential Offer</b> from <b>SpeedJet</b>',
                        state: 'Credential Offered'
                    }, {
                        label: 'Accept the <b>Credential Offer</b> under the <b>Pending Actions</b> section to receive your <b>Flight Ticket</b>',
                        state: 'Credential Issued'
                    },],
                successDialog: {
                    label: 'Great! Your <b>Flight Ticket Credential</b> has been issued. You can check it in the "Wallet" section of the Trential Wallet mobile app.',
                    openOnstate: 'Credential Issued'
                },
                attributesSectionTitle: '',
                formName: 'Flight Booking Form',
                formInputs: {
                    app: [{
                        type: 'text',
                        label: 'Name',
                        attributeName: 'Name',
                    }, {
                        type: 'text',
                        label: 'Citizen ID',
                        // defaultValue: '11',
                        attributeName: 'Citizen ID',
                    }, {
                        type: 'text',
                        label: 'Gender',
                        attributeName: 'Gender',
                    }, {
                        type: 'text',
                        label: 'Vaccination Details',
                        attributeName: 'Vaccine Name',
                    }, {
                        type: 'text',
                        label: 'Date of Dose',
                        attributeName: 'Date of Dose'
                    }
                    ],
                    infoText: 'Forms can be autofilled using the Trential Wallet using verified data, minimizing the chances of errors or fraud.'
                },
                buildPayloadMap: [
                    {
                        "name": "Departure Date",
                        value: new Date().toISOString()
                    },
                    {
                        "name": "Depart From",
                        "value": "Wadiya"
                    },
                    {
                        "name": "Going To",
                        "value": "New Delhi"
                    },
                    {
                        "name": "Name",
                    },
                    {
                        "name": "Gender",
                    },
                    {
                        "name": "Date of Dose"
                    }
                ]

            },
            congratulationsScreen: {
                title: 'Congratulations!',
                flexDirection: 'column',
                info: "<b>You have successfully booked the ticket. Hope you have a wonderful trip.</b>",
                image: '/svg/congratulationsFlight.svg'
                // attributes: { name: 'Full Name', 'gender': 'Gender' },
                // customImage: 'flightBookingCredentialDesign.js',

            },
            credentialInviteId: "fayda-travel",
        },
        'banking': {
            type: types.CREDENTIAL_PAGE,
            title: 'JP Mohan Chandra & Co',
            theme: '#9A1919',
            themeShade: 'linear-gradient(91.44deg, #FFD5AE 0%, #FFC1C1 98.28%)',
            themeSecondary: '#000000',
            flowScreen: {
                logo: '/svg/youWeBankLogo.svg',
                headerRightLogo: '/svg/blackSignout.svg',
                nav: ['About Us', 'Careers', 'Support'],
                showRestartDemoButton: false,
                // back: { title: "Home", link: '/' },
                title: "<span style=\"color:#000000;\">We help empower your dreams</span>",
                subtitle: "<span style=\"color:#444444;\">Apply for a Loan</span>",
                subtitle2: { text: 'Click the button below and Scan the QR using Trential Wallet to start your loan application procedure', color: '#444444' },
                startFlowButton: { title: "Apply" },
                image: '/svg/Bank.svg',
                jargon: {
                    title: "",
                    marginTop: '-6em',
                    cards: [{
                        title: "Open new Account",
                        subtitle: 'Open a New Bank account. Select from Savings, Current or Demat.'
                    }, {
                        title: "Apply for Checkbook",
                        subtitle: 'Easily apply for a new checkbook with a few clicks'
                    }, {
                        title: "Transfer Money",
                        subtitle: 'Transfer money to your loved ones quickly and securely'
                    },]
                },
                // action: {
                //     title: '',
                //     subtitle: 'Already have House? Check our services which you can try now',
                //     applications: [{
                //         title: 'Visit House Flow',
                //         description: 'Use this credential to apply for a house',
                //         link: '/house-workflow'
                //     },]
                // }
            },
            flowProgress: {
                type: types.FORM,
                qrCodeScannerTitle: 'Autofill using Trential Wallet',
                qrCodeScannerTitle2: 'Get Loan Approval Letter',
                // title: "Thanks for connecting with us!",
                // subtitle: 'To get Vaccine Certificate, complete the following steps',
                stateToOpenOn: null,
                disableQrDialogOnThisState: 'Proof Presentation',
                formSteps: [{
                    label: 'Scan the QR using <b>Trential Wallet</b>.',
                    state: 'Proof Presentation Received'
                }, {
                    label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Verification Request</b> from <b>JP Mohan Chandra & Co</b>',
                    state: 'Proof Presentation'
                }, {
                    label: 'Select the credentials you want to share the requested information from using the dropdown and tap <b>Share</b>',
                    info: ['You can share information in a single request using multiple credentials'],
                    state: 'Proof Presentation'
                }],
                steps: [
                    {
                        label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Credential Offer</b> from <b>JP Mohan Chandra & Co</b>',
                        state: 'Credential Offered'
                    }, {
                        label: 'Check the details and <b>accept the offer</b> to get your <b>Loan Approval Letter</b> in your wallet',
                        state: 'Credential Issued'
                    },],
                successDialog: {
                    label: 'Great! Your <b>Possession Certificate Credential<b> has been issued. You can check it in the "Wallet" section of the Trential Wallet mobile app.',
                    openOnstate: 'Credential Issued'
                },
                formName: 'House Loan Application Form',
                formInputs: {
                    app: [{
                        type: 'text',
                        label: 'Name',
                        attributeName: 'Full Name',
                    },
                    {
                        type: 'text',
                        label: 'Citizen ID',
                        // defaultValue: 11,
                        attributeName: 'Citizen ID',
                    }, {
                        type: 'text',
                        label: 'Year of Birth',
                        attributeName: 'Year of Birth',
                    }, {
                        type: 'text',
                        label: 'Address',
                        attributeName: 'Address'
                    }, {
                        type: 'text',
                        label: 'Company Name',
                        attributeName: 'Name of Company'
                    }, {
                        type: 'text',
                        // defaultValue: 1200000,
                        label: 'Salary',
                        attributeName: 'Salary',
                    },
                        // {
                        //     type: 'text',
                        //     maxLength: 3,
                        //     attributeName: 'interestRate',
                        //     endAdornment: "%"
                        // }, 
                    ],
                    manual: [{
                        type: 'text',
                        label: 'Loan Type',
                        defaultValue: 'House Loan',
                        attributeName: 'Loan Type',
                    }, {
                        type: 'text',
                        maxLength: 10,
                        label: 'Loan Amount',
                        defaultValue: '50,00,000',
                        attributeName: 'Loan Amount',
                        startAdornment: "₹"
                    }],
                    infoText: 'Forms can be autofilled using the Trential Wallet using verified data, minimizing the chances of errors or fraud.'
                },
                buildPayloadMap: [
                    // {
                    //     "name": "Loan Amount",
                    // },
                    // {
                    //     "name": "Interest Rate",
                    //     attributeName: "interestRate"
                    // },
                    {
                        "name": "Full Name",
                    },
                    {
                        "name": "Citizen ID",
                    }, {
                        "name": "Year of Birth",
                    },
                    {
                        "name": "Address",
                    },
                    {
                        "name": "Salary",
                    },
                    {
                        "name": "Name of Company",
                    },
                    {
                        "name": "Loan Type",
                    },
                ]

            },
            congratulationsScreen: {
                title: 'Congratulations!',
                flexDirection: 'column',
                info: "<b>You have received your Loan Approval Letter.</b><br><br><b>Share this document with your Realtor and buy your dream house.</b>",
                image: '/svg/bankingCongratulations.svg'
            },
            credentialInviteId: "banking",
        },
        'medical-college-id': {
            type: types.CREDENTIAL_PAGE,
            title: 'Addis Ababa University',
            theme: '#476D7F',
            themeShade: '#E8E8E8',
            themeSecondary: '#000000',
            flowScreen: {
                logo: '/svg/addisAbabaUniversity.svg',
                // headerRightLogo: '/svg/blackSignout.svg',
                nav: ['About', 'Academics', 'Admissions', 'Research'],
                // logoText: {
                //     primary: 'Addis Ababa University',
                // },
                showRestartDemoButton: false,
                // back: { title: "Home", link: '/' },
                title: "<span style=\"color:#000000;\">Inspire ingenuity. Make it happen!</span>",
                formPageTitle: '<span style=\"font-size:2.4rem;\">Start Your Addis Ababa Journey. Get Your Medical Student ID</span>',
                formImage: '/png/college-form.png',
                subtitle: "<span style=\"color:#444444;\">Register to get your Medical Student ID Card</span>",
                // subtitle2: { text: 'Click the button below and Scan the QR using Trential Wallet to start your loan application procedure', color: '#444444' },
                startFlowButton: { title: "Get Your ID Card" },
                image: '/png/collegeBuilding.png',
                // jargon: {
                //     title: "",
                //     marginTop: '-6em',
                //     cards: [{
                //         title: "Counselling Services",
                //         subtitle: 'Check out the latest curriculam and syllabus'
                //     }, {
                //         title: "Admission",
                //         subtitle: 'Enroll in Vocational Courses'
                //     }, {
                //         title: "Research",
                //         subtitle: 'Learn more about the Examination By Laws'
                //     },]
                // },
                action: {
                    title: '',
                    subtitle: 'Already have Medical Student ID Card? Check our services which you can try now',
                    applications: [{
                        title: 'Medical Degree',
                        description: 'Enroll in Vocational Courses',
                        link: '/medical-degree',
                        accentColor: '#8B0000'
                    }, {
                        title: 'Doctor Registration',
                        description: 'Learn more about the Examination By Laws',
                        link: '/medical-association',
                        accentColor: '#76B5D4'
                    }, {
                        title: 'Practice Telemedicine',
                        description: 'Learn more about telemedicine',
                        link: '/telemedicine',
                        accentColor: '#8B0000'
                    }]
                }
            },
            flowProgress: {
                type: types.FORM,
                infoColor: '#6D8FB1',
                infoBorderColor: '#E4F1FF',
                qrCodeScannerTitle: 'Autofill using Trential Wallet',
                qrCodeScannerTitle2: 'Get Your Medical Student ID',
                // title: "Thanks for connecting with us!",
                // subtitle: 'To get Vaccine Certificate, complete the following steps',
                stateToOpenOn: null,
                disableQrDialogOnThisState: 'Proof Presentation',
                formSteps: [{
                    label: 'Scan the QR using <b>Trential Wallet</b>.',
                    state: 'Proof Presentation Received'
                }, {
                    label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Verification Request</b> from <b>Addis Ababa University</b>',
                    state: 'Proof Presentation'
                }, {
                    label: 'The requested details will be auto-filled from your credentials, Tap <b>Share</b> to send the information',
                    info: ['You can share information in a single request using multiple credentials'],
                    state: 'Proof Presentation'
                }],
                steps: [
                    {
                        label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Credential Offer</b> from <b>Addis Ababa University</b>',
                        state: 'Credential Offered'
                    }, {
                        label: 'Check the details and <b>accept the offer</b> to get your <b>Medical Student ID Card</b> in your wallet',
                        state: 'Credential Issued'
                    },],
                successDialog: {
                    label: '<b>You now have the Medical Student ID Card in your wallet. Use it to verify yourself and avail the following benefits<b>\n - <b>Access Student Portal</b>\n - <b>Get Transcript & Degree</b>\n - <b>Avail Student Discounts and many more...</b>',
                    openOnstate: 'Credential Issued'
                },
                formName: 'University Registration Form',
                formInputs: {
                    app: [{
                        type: 'text',
                        label: 'Name',
                        attributeName: 'Name',
                    },
                    {
                        type: 'text',
                        label: 'Citizen ID',
                        // defaultValue: 11,
                        attributeName: 'Citizen ID',
                    }, {
                        type: 'text',
                        label: 'Year of Birth',
                        attributeName: 'Year of Birth',
                    }, {
                        type: 'text',
                        label: 'Address',
                        attributeName: 'Address'
                    },
                        // {
                        //     type: 'text',
                        //     maxLength: 3,
                        //     attributeName: 'interestRate',
                        //     endAdornment: "%"
                        // }, 
                    ],
                    // manual: [],
                    infoText: 'Forms can be autofilled using the Trential Wallet with verified data, minimizing the chances of errors or fraud.'
                },
                buildPayloadMap: [
                    // {
                    //     "name": "Loan Amount",
                    // },
                    // {
                    //     "name": "Interest Rate",
                    //     attributeName: "interestRate"
                    // },
                    {
                        "name": "Name",
                    },
                    {
                        "name": "Citizen ID",
                    }, {
                        "name": "Year of Birth",
                        attributeName: "Year of Birth"
                    },
                    {
                        "name": "Address",
                    },
                ]

            },
            congratulationsScreen: {
                title: 'Congratulations!',
                flexDirection: 'column',
                info: "<b>You now have the Medical Student ID Card in your wallet. Use it to verify yourself and avail the following benefits</b> \n - <b>Access Student Portal</b>\n - <b>Get Transcript & Degree</b>",
                image: '/svg/educationCongratulations.svg'
            },
            credentialInviteId: "medical-college-id",
        },
        'medical-degree': {
            type: types.CREDENTIAL_PAGE,
            title: 'Addis Ababa University: Get Your Degree',
            theme: '#476D7F',
            themeShade: 'linear-gradient(180deg, rgba(144, 167, 177, 0.3) 0%, rgba(58, 99, 119, 0.7) 100%)',
            themeSecondary: '#706EDC',
            flowScreen: {
                logo: '/svg/addisAbabaUniversityDegree.svg',
                headerTextColor: '#476D7F',
                nav: ['About Us', 'Academics', 'Admissions', 'Research'],
                showRestartDemoButton: false,
                // back: { title: "Home", link: '/' },
                formPageTitle: '<span style=\"font-size:2.4rem;color:#000000;\">Get your Medical Degree</span>',
                formImage: '/png/medical-degree-form.png',
                title: "<span style=\"color:white;font-family: Montagu Slab; font-weight: 700; font-size: 3.5rem;\">182nd Convocation</span> ",
                subtitle: '<span style=\"color:white;font-family: Montagu Slab; font-weight: 500;\">Congratulations to all the graduating students</span>',
                startFlowButton: { title: "Get Your Degree" },
                image: '/png/hats.png',
                // jargon: {
                //     title: "",
                //     marginTop: '-6em',
                //     cards: [{
                //         title: "Counselling Services",
                //         subtitle: 'Check out the latest curriculam and syllabus'
                //     }, {
                //         title: "Admission",
                //         subtitle: 'Enroll in Vocational Courses'
                //     }, {
                //         title: "Research",
                //         subtitle: 'Learn more about the Examination By Laws'
                //     },]
                // },
                action: {
                    title: '',
                    subtitle: 'Already have Degree? Check our services which you can try now',
                    applications: [{
                        title: 'Doctor Registration',
                        description: 'Learn more about the Examination By Laws',
                        link: '/medical-association',
                        accentColor: '#76B5D4'
                    }, {
                        title: 'Practice Telemedicine',
                        description: 'Learn more about telemedicine',
                        link: '/telemedicine',
                        accentColor: '#8B0000'
                    }]
                }
            },
            flowProgress: {
                type: types.FORM,
                infoColor: '#6D8FB1',
                infoBorderColor: '#E4F1FF',
                qrCodeScannerTitle: 'Autofill using Trential Wallet',
                qrCodeScannerTitle2: 'Get Your Medical Degree',
                title: "Thanks for connecting with us!",
                subtitle: 'To get Medical Degree, complete the following steps',
                stateToOpenOn: null,
                disableQrDialogOnThisState: 'Proof Presentation',
                formSteps: [{
                    label: 'Scan the QR using <b>Trential Wallet</b>.',
                    state: 'Proof Presentation Received'
                }, {
                    label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Verification Request</b> from <b>Addis Ababa University Convocation Portal</b>',
                    state: 'Proof Presentation'
                }, {
                    label: 'The requested details will be auto-filled from your credentials, Tap <b>Share</b> to send the information',
                    info: ['You can share information in a single request using multiple credentials'],
                    state: 'Proof Presentation'
                }],
                steps: [
                    {
                        label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Credential Offer</b> from <b>Addis Ababa University</b>',
                        state: 'Credential Offered'
                    }, {
                        label: 'Check the details and <b>accept the offer</b> to get your <b>Medical Student ID Card</b> in your wallet',
                        state: 'Credential Issued'
                    },],
                successDialog: {
                    label: "<b>You now have your Medical Degree. This can be used to apply for:</b> \n - <b> Doctor registration</b> \n - <b>Job Applications and much more...</b>",
                    openOnstate: 'Credential Issued'
                },
                formName: 'Medical Degree Form',
                formInputs: {
                    app: [{
                        type: 'text',
                        label: 'Name',
                        attributeName: 'Name',
                    },
                    {
                        type: 'text',
                        label: 'Roll Number',
                        // defaultValue: 11,
                        attributeName: 'Roll Number',
                    },],
                    // manual: [],
                    infoText: 'Forms can be autofilled using the Trential Wallet with verified data, minimizing the chances of errors or fraud.'
                },
                buildPayloadMap: [
                    // {
                    //     "name": "Loan Amount",
                    // },
                    // {
                    //     "name": "Interest Rate",
                    //     attributeName: "interestRate"
                    // },
                    {
                        "name": "Name",
                    },
                    {
                        "name": 'Roll Number',
                    }
                ]

            },
            congratulationsScreen: {
                title: 'Congratulations!',
                flexDirection: 'column',
                info: "<b>You now have your Medical Degree. This can be used to apply for:</b> \n - <b> Doctor registration</b> \n - <b>Job Applications and much more...</b>",
                image: '/svg/degreeCongratulations.svg'
            },
            credentialInviteId: "medical-degree",
        },
        'doctor-workflow': {
            type: types.WORKFLOW_PAGE,
            title: 'Doctor Registration',
            subtitle: 'Register as a Doctor',
            theme: '#1F86DC',
            themeShade: '#F0F6FA',
            pageToOpenUponTutorialStart: '/medical-college-id',
            successPage: {
                title: "Congratulations on getting your Medical Credentials",
                subtitle: 'We hope you enjoyed the demo and got to learn how to use Trential Wallet',
                buttons: [{ title: "Try our Travel flow", link: [types.START_WORKFLOW, 'travel-workflow'] },
                { title: "Back to Start", link: '/citizen-id' }],
                steps: [{
                    title: 'To share information from different documents ',
                    icon: '/svg/successPageStepIcon1.svg'
                }, {
                    title: 'To verify your eligibility without sharing your details',
                    icon: '/svg/successPageStepIcon2.svg'
                }, {
                    title: 'Life is easy using Trential Wallet',
                    icon: '/svg/successPageStepIcon3.svg'
                }],
                closingStatement: 'For any other use case, Reach out to us. <a href="https://www.trential.com/" target="_blank"><b>click here</b></a>',
                images: ['/png/education.png', '/png/degree.png', '/png/medical-license.png', '/png/telemedicine.png'],
                links: ['/medical-college-id', '/medical-degree', '/medical-association', '/telemedicine'],
            },
            stepperSteps: {
                steps: ['Medical Student ID Card', 'Medical Degree', 'Medical License', 'Practice Telemedicine'],
                links: ['/medical-college-id', '/medical-degree', '/medical-association', '/telemedicine'],
                stepsCompleted: 0
            },
            welcomeScreen: {
                back: { title: "Home", link: '/' },
                title: {
                    1: "Welcome",
                    2: 'Great!',
                    3: 'Wow! That was quick.',
                    4: 'Yay!'
                },
                subtitle: {
                    1: "to a decentralised identity exchange system!",
                    2: 'You have a Medical Degree from Identity University now',
                    3: 'Your job offer is conveyed and saved in your wallet in the Trential app.',
                    4: 'You have now successfully received a Education and its details is saved in Trential app wallet.<br><br>Everything is set now.'
                },
                heroImage: {
                    1: '/svg/credentialShare.svg',
                    2: '/svg/bullseyeWithArrow.svg',
                    3: '/svg/bullseyeWithArrow.svg',
                    4: '/svg/bullseyeWithArrow.svg'
                },
                stepperSteps: {
                    steps: ['Start', 'Get Medical Student ID Card', 'Get Medical Degree', 'Get Medical License','Apply for a Telemedicine'],
                    links: ['/medical-college-id', '/medical-degree', '/medical-association', '/telemedicine'],
                    stepsCompleted: 0
                },

            },
        },
        'medical-association': {
            type: types.CREDENTIAL_PAGE,
            title: 'Doctor Registration',
            theme: '#476D7F',
            themeShade: '#E8E8E8',
            themeSecondary: '#000000',
            flowScreen: {
                logo: '/svg/emaLogo.svg',
                headerRightLogo: '/svg/blackSignout.svg',
                nav: ['About EMA', 'Careers', 'Support'],
                // logoText: {
                //     primary: 'Addis Ababa University',
                // },
                showRestartDemoButton: false,
                // back: { title: "Home", link: '/' },
                title: "<span style=\"color:#000000;\">Doctor Registration</span>",
                formPageTitle: '<span style=\"font-size:2.4rem;\">Get your Medical License</span>',
                formImage: '/png/medical-degree-form.png',
                subtitle: "<span style=\"color:#444444;\">Get your Medical License</span>",
                subtitle2: { text: 'Click the button below and Scan the QR using Trential Wallet to get your License', color: '#444444' },
                startFlowButton: { title: "Get License" },
                image: '/svg/doctorRegistrationHero.svg',
                // jargon: {
                //     title: "",
                //     marginTop: '-6em',
                //     cards: [{
                //         title: "Apply for jobs",
                //         subtitle: 'Easily practice your domain'
                //     }, {
                //         title: "Join initiatives",
                //         subtitle: 'Connect and apply'
                //     }, {
                //         title: "Seminars & conferences",
                //         subtitle: 'Book your awaited webinar'
                //     },]
                // },
                action: {
                    title: '',
                    subtitle: 'Already have Medical License? Check our services which you can try now',
                    applications: [{
                        title: 'Practice Telemedicine',
                        description: 'Learn more about telemedicine',
                        link: '/telemedicine',
                        accentColor: '#8B0000'
                    }]
                }
            },
            flowProgress: {
                type: types.FORM,
                infoColor: '#6D8FB1',
                infoBorderColor: '#E4F1FF',
                qrCodeScannerTitle: 'Autofill using Trential Wallet',
                qrCodeScannerTitle2: 'Get Your Medical License',
                title: "Thanks for connecting with us!",
                subtitle: 'To get Medical License, complete the following steps',
                stateToOpenOn: null,
                disableQrDialogOnThisState: 'Proof Presentation',
                formSteps: [{
                    label: 'Scan the QR using <b>Trential Wallet</b>.',
                    state: 'Proof Presentation Received'
                }, {
                    label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Verification Request</b> from <b>Ethiopian Medical Association</b>',
                    state: 'Proof Presentation'
                }, {
                    label: 'The requested details will be auto-filled from your credentials, Tap <b>Share</b> to send the information',
                    info: ['You can share information in a single request using multiple credentials'],
                    state: 'Proof Presentation'
                }],
                steps: [
                    {
                        label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Credential Offer</b> from <b>Ethiopian Medical Association</b>',
                        state: 'Credential Offered'
                    }, {
                        label: 'Check the details and <b>accept the offer</b> to get your <b>Medical License</b> in your wallet',
                        state: 'Credential Issued'
                    },],
                successDialog: {
                    label: "<b>You now have the Medical License in your wallet. Use it to verify yourself and avail the following benefits</b> \n - <b>Apply for jobs</b>\n - <b>Join initiatives</b>",
                    openOnstate: 'Credential Issued'
                },
                formName: 'Medical License Form',
                formInputs: {
                    app: [{
                        type: 'text',
                        label: 'Name',
                        attributeName: 'Name',
                    },
                    {
                        type: 'text',
                        label: 'Year of Birth',
                        attributeName: 'Year of Birth',
                    },
                    {
                        type: 'text',
                        label: 'Gender',
                        attributeName: 'Gender',
                    }, {
                        type: 'text',
                        label: 'College Name',
                        attributeName: 'College Name',
                    }, {
                        type: 'text',
                        label: 'Year of Passing',
                        attributeName: 'Year of Passing',
                    }, {
                        type: 'text',
                        label: 'Programme',
                        attributeName: 'Programme',
                    },
                    {
                        type: 'text',
                        label: 'Roll Number',
                        attributeName: 'Roll Number',
                    },],
                    // manual: [],
                    infoText: 'Forms can be autofilled using the Trential Wallet with verified data, minimizing the chances of errors or fraud.'
                },
                buildPayloadMap: [{
                    name: 'Name',
                },
                {
                    name: 'Year of Birth',
                },
                {
                    name: 'Gender',
                }, {
                    name: 'College Name',
                }, {
                    name: 'Year of Passing',
                }, {
                    name: 'Programme',
                },
                {
                    name: 'Roll Number',
                },
                ]

            },
            congratulationsScreen: {
                title: 'Congratulations!',
                flexDirection: 'column',
                info: "<b>You now have your Medical License. This can be used for</b> \n - <b>Apply for Medical Practice</b>\n - <b>Apply for Telemedicine Network</b> \n - <b>Employment Verification and many more...</b>",
                image: '/svg/doctorRegistrationCongratulations.svg'
            },
            credentialInviteId: "medical-association",
        },
        'telemedicine': {
            type: types.CREDENTIAL_PAGE,
            title: 'Register on ETN',
            theme: '#476D7F',
            themeShade: '#D2ECFF',
            themeSecondary: '#000000',
            flowScreen: {
                logo: '/svg/etnLogo.svg',
                // headerRightLogo: '/svg/blackSignout.svg',
                nav: ['About', 'Academics', 'Admissions', 'Research'],
                // logoText: {
                //     primary: 'Register on ETN',
                // },
                showRestartDemoButton: false,
                // back: { title: "Home", link: '/' },
                title: "<span style=\"color:#000000;\">Register on ETN</span>",
                formPageTitle: '<span style=\"font-size:2.4rem;\">Patients are seeking you.</span>',
                formImage: '/png/telemedicine-form-2.png',
                subtitle: "<span style=\"color:#444444;\">Patients are seeking doctors like you.</span>",
                subtitle2: { text: 'Click the button below and Scan the QR using Trential Wallet. Start your digital journey with ETN Profile', color: '#444444' },
                startFlowButton: { title: "Get Started" },
                image: '/svg/telemedicineHero.svg',
                jargon: {
                    title: "",
                    marginTop: '-6em',
                    cards: [{
                        title: "Instant consultation",
                        subtitle: 'Connect within 60 seconds'
                    }, {
                        title: "Find doctors near you",
                        subtitle: 'Confirmed appointment'
                    }, {
                        title: "Diagnoses",
                        subtitle: 'Safe and trusted Labarotaries'
                    },]
                },
            },
            flowProgress: {
                type: types.FORM,
                infoColor: '#6D8FB1',
                infoBorderColor: '#E4F1FF',
                qrCodeScannerTitle: 'Autofill using Trential Wallet',
                qrCodeScannerTitle2: 'Register on ETN',
                // title: "Thanks for connecting with us!",
                // subtitle: 'To get Vaccine Certificate, complete the following steps',
                stateToOpenOn: null,
                disableQrDialogOnThisState: 'Proof Presentation',
                formSteps: [{
                    label: 'Scan the QR using <b>Trential Wallet</b>.',
                    state: 'Proof Presentation Received'
                }, {
                    label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Verification Request</b> from <b>Addis Ababa University</b>',
                    state: 'Proof Presentation'
                }, {
                    label: 'The requested details will be auto-filled from your credentials, Tap <b>Share</b> to send the information',
                    info: ['You can share information in a single request using multiple credentials'],
                    state: 'Proof Presentation'
                }],
                steps: [
                    {
                        label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Credential Offer</b> from <b>Addis Ababa University</b>',
                        state: 'Credential Offered'
                    }, {
                        label: 'Check the details and <b>accept the offer</b> to get your <b>Medical Student ID Card</b> in your wallet',
                        state: 'Credential Issued'
                    },],
                successDialog: {
                    label: '<b>You now have the Medical Student ID Card in your wallet. Use it to verify yourself and avail the following benefits<b>\n - <b>Access Student Portal</b>\n - <b>Get Transcript & Degree</b>\n - <b>Avail Student Discounts and many more...</b>',
                    openOnstate: 'Credential Issued'
                },
                formName: 'Register',
                formInputs: {
                    app: [{
                        type: 'text',
                        label: 'Name',
                        attributeName: 'Name',
                    },
                    {
                        type: 'text',
                        label: 'Medical Registration Number',
                        // defaultValue: 11,
                        attributeName: 'Medical Registration Number',
                    }, {
                        type: 'text',
                        label: 'Date of Expiry',
                        attributeName: 'Date of Expiry',
                    }, {
                        type: 'text',
                        label: 'Designation',
                        options: [{
                            name: 'Doctor',
                            value: 'Doctor',
                        }, {
                            name: 'Surgeon',
                            value: 'Surgeon',
                        }, {
                            name: 'Nurse',
                            value: 'Nurse',
                        }],
                        attributeName: 'Designation'
                    },
                        // {
                        //     type: 'text',
                        //     maxLength: 3,
                        //     attributeName: 'interestRate',
                        //     endAdornment: "%"
                        // }, 
                    ],
                    // manual: [],
                    infoText: 'Forms can be autofilled using the Trential Wallet with verified data, minimizing the chances of errors or fraud.'
                },
                buildPayloadMap: [
                    {
                        "name": "Name",
                    },
                    {
                        "name": 'Medical Registration Number',
                    }, {
                        "name": 'Date of Expiry',
                        attributeName: 'Date of Expiry'
                    },
                    {
                        "name": "Designation",
                    },
                ],
                enableFakeSubmission: true

            },
            congratulationsScreen: {
                title: 'Congratulations!',
                flexDirection: 'column',
                info: "<b>Your ID has been successfully verified and you have  registered on Ethiopian Telemedicine Network.",
                image: '/svg/telemedicineCongratulations.svg'
            },
            credentialInviteId: "telemedicine",
        },
    }
}
