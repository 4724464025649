import dayjs from "dayjs";
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

export const getClippedLengthTitle = (name, limit = 30) => name?.length > limit ? `${name?.substring(0, limit)}...` : name;

export const underscoreCaseToWords = (str) => {
  return str
    .split("_")
    .map((word) => word.replace(word.charAt(0), word.charAt(0).toUpperCase()))
    .join(" ");
};

// const camelCaseToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
// const camelCaseToWords = str => underscoreCaseToWords(camelCaseToSnakeCase(str))

export const stringToCamelCase = (str) => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

export const camelCaseToString = (camelCasedStr) => {
  const temp = camelCasedStr.replace(/([A-Z])/g, " $1");
  return temp.charAt(0).toUpperCase() + temp.slice(1);
};

export const comparator = (property, orderBy = 1, setSortOrder) => {
  setSortOrder(-1 * orderBy);
  return function(a, b) {
    if (a[property] > b[property]) return orderBy;
    else if (a[property] < b[property]) return -1 * orderBy;
    else return 0;
  };
};

export const convertToNestedForm = (candidate) => {
  const formattedCandidate = {};

  Object.keys(candidate).forEach((key) => {
    if (key === "email" || key === "mobile" || key === "name" || key === "_id" || key === "updatedAt" || key === "createdAt") {
      formattedCandidate[key] = candidate[key];
    } else {
      if (formattedCandidate["profile"]) {
        formattedCandidate["profile"][key] = candidate[key];
      } else {
        formattedCandidate["profile"] = {};
        formattedCandidate["profile"][key] = candidate[key];
      }
    }
  });
  return formattedCandidate;
};

export const convertToNonNestedForm = (data) => data?.map((candidate) => {
  const formattedCandidate = Object.assign({}, candidate);
  if (candidate.profile) {
    Object.keys(candidate.profile).forEach((key) => {
      formattedCandidate[key] = candidate.profile[key];
    });
    delete formattedCandidate["profile"];
  }
  delete formattedCandidate["PRIVATE_INVALID"];
  return formattedCandidate;
});

export const checkIfObjectIsNotEmpty = (obj) => {
  let check = false;
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      check = true;
    }
  });
  return check;
};

export const changeToSentenceCase = (text) => {
  if (text.includes("ifsc")) return "IFSC Code";
  if (text.includes("dob")) return "Date of Birth";
  // in case of Id, eg: userId
  if (text.charAt(text.length - 1) === "d" && text.charAt(text.length - 2) === "I") {
    const strToModify = text.split("Id")[0];
    const result = strToModify.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1) + " ID";
  }
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const deleteFromErrors = (errors, setErrors, fieldToDelete, isArray) => {
  const tempErrors = {...errors};
  if (isArray) fieldToDelete.forEach((field) => delete tempErrors[field]);
  else delete tempErrors[fieldToDelete];
  setErrors(tempErrors);
};

export const downloadFile = (value, fileName) => {
  const a = document.createElement("a");
  a.href = value;
  a.download = fileName;
  a.click();
};

export const downloadJsonFile = (exportObj, exportName) => {
  const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(exportObj);
  const downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", exportName + ".json");
  document.body.appendChild(downloadAnchorNode);
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

export const b64toURL = async (b64Data) => {
  const blob = await (await fetch(b64Data)).blob();
  return URL.createObjectURL(blob);
};

export const isValidUrl = (urlString) => {
  const urlPattern = new RegExp("^(https?:\\/\\/)?" + // validate protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
    "(\\#[-a-z\\d_]*)?$", "i"); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export function parseStringTemplate(str, obj) {
  const parts = str.split(/\{\{(?!\d)[\wæøåÆØÅ]*\}}/);
  const args = str.match(/[^{}]+(?=})/g) || [];
  const parameters = args.map((argument) => obj[argument] || (obj[argument] === undefined ? "" : obj[argument]));
  return String.raw({ raw: parts }, ...parameters);
}

export const openInNewTab = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};


export const getTrimmedText = (title, length) => {
  if (title.length >= length) return `${title.slice(0, length)}...`;
  return title;
};

// export function getTimeAgo(h) {
//   const ago = new Date(parseInt(h, 16) * 1000); // convert to decimal unix time
//   const d = dayjs(ago).fromNow()
//   return d;
// }
export function getTimeAgo(h) {
  const ago = new Date(h); // convert to decimal unix time
  const d = dayjs(ago).fromNow()
  return d;
}

export function displayTime(h) {
  const ago = new Date(h); // convert to decimal unix time

  const d = dayjs(ago).format('DD/MM/YYYY HH:mm')

  return d;
}

export const downloadQR = (name) => {
  const canvas = document.querySelector("canvas");
  const link = document.createElement("a");
  link.href = canvas.toDataURL();
  link.download = `${name}-QR.png`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const fitToBoxSvg = async (svgStr) => {
  return new Promise((resolve, reject) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgStr, "image/svg+xml");


    if (!doc.documentElement.getAttribute("viewBox")) {
      doc.documentElement.setAttribute("viewBox", "0 0 " +
        doc.documentElement.getAttribute("width") + " " +
        doc.documentElement.getAttribute("height"));
    }
    doc.documentElement.setAttribute("height", "100%");
    doc.documentElement.setAttribute("width", "100%");
    doc.documentElement.removeAttribute("x");
    doc.documentElement.removeAttribute("y");

    setTimeout(async () => {
      resolve(doc.documentElement.outerHTML);
    }, 500);
  });
};
