
import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, CircularProgress, Grid, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { sendCredential } from "../../api/calls";
import verified from '../../assets/svg/verified.svg';
import { config, types } from "../../config/config";
import { changeHomeState, manageConfetti } from "../../containers/home/slice";
import GenerateInput from "../common/generateInput";
import MarkdownWrapper from "../common/markdownWrapper.js";
import CustomDialog from "../customDialog";
import Congratulations from "./cards/congratulations";
import { Progress } from "./qrPopup";

export default function Form({ flowProgress, latestSseResponse,
    setLatestSseResponse, setOpenQrPopup, page, uuid, setQrMessage, company, setOpenForm,
    setInvitationUrl }) {
    const theme = useTheme();
    const stepperRef = useRef()
    const submitButtonRef = useRef()
    const [indexToApplyAnimation, setIndexToApplyAnimation] = useState(-1)
    const [attributes, setAttributes] = useState({})
    const [origin, setOrigin] = useState({})
    const [loading, setLoading] = useState(false)
    const [openAwaitingCredentialAcceptance, setOpenAwaitingCredentialAcceptance] = useState(false)

    const { currentWorkflowStep, workflowStarted } = useSelector(s => s.home)
    const history = useHistory();
    const dispatch = useDispatch();
    const checkIfDone = (i) => {
        const activeStep = flowProgress?.formSteps?.findLastIndex((x) => x.state === latestSseResponse?.event)
        if (activeStep >= i) {
            return true
        }
        return false;
    }

    const checkIfDoneForMainSteps = (i) => {
        const activeStep = flowProgress?.steps?.findLastIndex((x) => x.state === latestSseResponse?.event)
        if (activeStep >= i) {
            return true
        }
        return false;
    }

    //console.log('latestSseResponse', latestSseResponse, attributes)

    useEffect(() => {
        if (latestSseResponse?.payload?.length > 0) {
            let attrs = { ...attributes };
            let origins = {};
            latestSseResponse?.payload?.forEach((x) => {
                attrs[x.name] = x.value
                origins[x.name] = x.origin
            })
            setAttributes(attrs)
            setOrigin(origins)
        }
    }, [latestSseResponse?.payload])

    const initializeAttributes = () => {
        if (flowProgress.formInputs?.app.length > 0) {
            let attrs = {};
            flowProgress.formInputs?.app?.forEach((x) => {
                //console.log('============', x)
                if (x.defaultValue) {
                    attrs[x.attributeName] = x.defaultValue
                }
            })
            flowProgress.formInputs?.manual?.forEach((x) => {
                //console.log('============', x)
                if (x.defaultValue) {
                    attrs[x.attributeName] = x.defaultValue
                }
            })
            setAttributes(attrs)
            setOrigin({})
        }
    }

    useEffect(() => {
        initializeAttributes()
    }, [])

    const manageResponseSideEffect = () => {
        console.log('latestSseResponse::', latestSseResponse, flowProgress, company)
        if (checkIfDoneForMainSteps(flowProgress.steps?.length - 1)) {
            dispatch(manageConfetti(true))
        }
        if (latestSseResponse && !checkIfDone(0)) {
            window.scrollTo({
                top: stepperRef?.current?.getBoundingClientRect().bottom, behavior: 'smooth'
            });
        }
    }
    useEffect(() => {
        manageResponseSideEffect()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latestSseResponse])

    const onAttributesChange = (key, value) => {
        setAttributes({ ...attributes, [key]: value })
    }

    useEffect(() => {
        const activeStep = flowProgress?.formSteps.findLastIndex((x) => x.state === latestSseResponse?.event) + 1
        if (activeStep === flowProgress?.formSteps.length - 1) {
            window.scrollTo({
                top: submitButtonRef?.current?.getBoundingClientRect().bottom, behavior: 'smooth'
            });
            setIndexToApplyAnimation('submit');
            setTimeout(() => {
                setIndexToApplyAnimation(-1);
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const checkIfAnyKeyIsEmpty = (key) => {
        return [...flowProgress.formInputs?.app,
        ...(flowProgress.formInputs?.manual ?? [])]?.every((x, i) => {
            console.log(i, 'attributes[x.attributeName]', attributes, x.attributeName, !attributes[x.attributeName])
            if (!attributes[x.attributeName]) {
                return false
            }
            return true
        })
    }
    console.log('checkIfAnyKeyIsEmpty()', checkIfAnyKeyIsEmpty(), checkIfDone(flowProgress?.formSteps.length - 1))

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: config.pages[company]?.flowScreen?.customElements?.formTopScreen ? '' : '-6em',
            paddingBottom: '6em'
        }}> <Typography
            variant="body1"
            color="primary"
            sx={{
                fontSize: { xs: '1vw', sm: '4vw', md: '2.4rem' }, fontWeight: 600,
                color: { xs: '#000000', md: '#ffffff' },
                textAlign: 'center'
            }}>
                <MarkdownWrapper>
                    {config.pages[company]?.flowScreen?.formPageTitle}
                </MarkdownWrapper>
            </Typography> <Card sx={{
                width: { xs: '92%', md: '80%' },
                padding: '2em',
                border: "1px solid #DADADA",
                borderRadius: "16px"
            }}>


                <Grid container direction="column" justifyContent={"flex-start"} alignItems="flex-start"
                >
                    {/* <Grid container justifyContent={"flex-start"} alignItems="stretch"
                    direction="row"
                    ref={stepperRef}
                >{
                        flowProgress?.formSteps.map((step, index) => <Grid
                            key={"form-steps-" + index}
                            component={motion.div}
                            initial={{ opacity: 0, scale: 0 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ type: "spring", stiffness: 100 }}
                            item
                            sx={{
                                border: '2px solid ' + (!checkIfDone(index)
                                    ? '#F7CC1D' : theme.palette.secondary.main),
                                borderRadius: '8px',
                                width: '150px',
                                margin: '1em',
                                padding: '1em',
                                height: 'initial'
                            }}
                            className={index === indexToApplyAnimation ? "shake" : ''}>
                            {!checkIfDone(index) ? <img src={clock3} alt="clock" /> : <motion.img
                                initial={{ opacity: 0, scale: 0 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ type: "spring", stiffness: 100 }}
                                src={checkInGreenCircle} alt="check" />}
                            <Typography variant="body2"
                                sx={{ color: !checkIfDone(index) ? '#F7CC1D' : theme.palette.secondary.main }}>{step.label}</Typography>
                        </Grid>)
                    }</Grid> */}
                    {/* <Typography variant="body1">{flowProgress.attributesSectionTitle}</Typography> */}

                    <Typography variant="h2">{flowProgress.formName}</Typography>
                    <Grid container
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            marginTop: '1em'
                        }}>
                        {flowProgress.formInputs?.app?.map((input, i) => <Grid
                            key={'inputs-' + i}
                            item
                            sx={{ margin: '1em', marginLeft: 0, width: { xs: '100%', sm: '45%', md: '30%' } }}>
                            <GenerateInput
                                // disabled={checkIfDone(flowProgress?.formSteps.length - 1) || latestSseResponse?.payload?.findIndex(x => x
                                //     .name === input.attributeName) > -1}
                                readOnly
                                inputData={input}
                                value={attributes[input.attributeName]}
                                onChange={(val) => {
                                    onAttributesChange(input.attributeName, val)
                                }}
                                startAdornment={input.startAdornment ? <MarkdownWrapper>
                                    {input.startAdornment}</MarkdownWrapper>
                                    : null}
                                endAdornment={(<>
                                    {attributes[input.attributeName] ? <img src={verified} alt="verified" /> : null}
                                    {input.endAdornment ? <MarkdownWrapper>
                                        {input.endAdornment}
                                    </MarkdownWrapper> : null}
                                </>)}
                                helperText={origin[input.attributeName] ?
                                    (<>Fetched from <b style={{ color: '#000000' }}>
                                        {origin[input.attributeName]}</b></>) : null}
                            /></Grid>)}



                    </Grid>
                    {flowProgress.formInputs?.infoText ? <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "6px 16px",
                        gap: "8px",
                        width: "fit-content",
                        background: config.pages[company]?.themeShade,
                        borderRadius: "10px"
                    }}>
                        {flowProgress.formInputs?.infoText}
                    </Box> : null}

                    <Button variant="outlined"
                        sx={{ margin: '1em 1em 1em 0' }}
                        onClick={() => {
                            initializeAttributes()
                            setInvitationUrl(null)
                            setQrMessage("")
                            setLatestSseResponse(null);
                            setOpenQrPopup('showQr')
                        }}>Autofill with Trential Wallet</Button>



                    {flowProgress.formInputs?.manual ? <><Typography variant="h2">Fill manually</Typography>
                        <Grid container
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                marginTop: '1em'
                            }}>
                            {flowProgress.formInputs?.manual?.map((input, i) => <Grid
                                key={'inputs-' + i}
                                item
                                sx={{ margin: '1em', marginLeft: 0, width: { xs: '100%', sm: '45%', md: '30%' } }}>
                                <GenerateInput
                                    disabled={checkIfDone(flowProgress?.formSteps.length - 1) || latestSseResponse?.payload?.findIndex(x => x
                                        .name === input.attributeName) > -1}
                                    inputData={input}
                                    value={attributes[input.attributeName]}
                                    onChange={(val) => {
                                        onAttributesChange(input.attributeName, val)
                                    }}
                                    startAdornment={input.startAdornment ? <MarkdownWrapper>
                                        {input.startAdornment}</MarkdownWrapper>
                                        : null}
                                    endAdornment={input.endAdornment ? <MarkdownWrapper>
                                        {input.endAdornment}</MarkdownWrapper>
                                        : null}
                                /></Grid>)}



                        </Grid>
                    </> : null}
                    <Grid container justifyContent={"flex-end"} alignItems="center"
                        sx={{
                            // flexDirection: { xs: 'column', md: 'row' },
                            // justifyContent: { xs: 'center', md: 'flex-start' },
                            margin: '1em 0'
                        }}>
                        <LoadingButton
                            ref={submitButtonRef}
                            className={'submit' === indexToApplyAnimation ? "shake" : ''}
                            loading={loading}
                            disabled={!checkIfAnyKeyIsEmpty() || loading || !checkIfDone(flowProgress?.formSteps.length - 1)}
                            variant="contained"
                            sx={{ marginLeft: 'auto' }}
                            onClick={async () => {
                                setLoading(true)
                                if (flowProgress.enableFakeSubmission) {
                                    dispatch(manageConfetti(true))
                                    setOpenAwaitingCredentialAcceptance(true)
                                } else {
                                    await sendCredential({
                                        attributes: flowProgress.buildPayloadMap ?
                                            flowProgress.buildPayloadMap.map((x) => {
                                                if (x.value) {
                                                    return x
                                                } else {
                                                    return { name: x.name, value: attributes[x.attributeName ?? x.name] }
                                                }
                                            })
                                            : Object.keys(attributes)
                                                .map(key => ({
                                                    name: key,
                                                    value: attributes[key]
                                                })),
                                        template: page,
                                        uid: uuid
                                    }).then(res => {
                                        if (res) {
                                            setOpenAwaitingCredentialAcceptance(true)
                                        }
                                    });
                                }
                                setLoading(false)

                            }}>Submit Application</LoadingButton>
                        {/* <Button variant="text"
                        sx={{ margin: '1em 1em 1em 0' }}
                        onClick={() => {
                            setLatestSseResponse(null);
                            setOpenQrPopup(false)
                        }}>Show QR code again</Button> */}
                    </Grid>

                    {openAwaitingCredentialAcceptance ? <CustomDialog
                        title={checkIfDoneForMainSteps(flowProgress.steps.length - 1) ? config.pages[company]?.congratulationsScreen.title : flowProgress.qrCodeScannerTitle2}
                        maxWidth={checkIfDoneForMainSteps(flowProgress.steps.length - 1) ? "sm" : 'md'}
                        fullWidth
                        open={openAwaitingCredentialAcceptance}
                        setOpen={() => { }}>
                        {checkIfDoneForMainSteps(flowProgress.steps.length - 1) || flowProgress.enableFakeSubmission ?
                            <Congratulations
                                setLatestSseResponse={setLatestSseResponse}
                                attributes={attributes}
                                company={company}
                                setOpenQrPopup={setOpenQrPopup}
                                onFinish={() => {
                                    console.log('HIIIIIIIIIIIII', workflowStarted, config.pages[workflowStarted]
                                        ?.stepperSteps?.links?.includes('/' + company), company)

                                    if (workflowStarted) {
                                        if (currentWorkflowStep === config.pages[workflowStarted].stepperSteps.steps.length - 1) {
                                            history.push({ pathname: '/', state: { success: true } })
                                        } else {
                                            if (workflowStarted && config.pages[workflowStarted]
                                                ?.stepperSteps?.links?.includes('/' + company)) {

                                                let index = config.pages[workflowStarted]
                                                    ?.stepperSteps?.links?.findLastIndex(x => x === ('/' + company))

                                                if (config.pages[workflowStarted]
                                                    ?.stepperSteps?.links[index + 1]) {
                                                    history.push(config.pages[workflowStarted]
                                                        ?.stepperSteps?.links[index + 1])
                                                }
                                                // console.log('HIIIIIIIII', index, config.pages[workflowStarted]
                                                //     ?.stepperSteps?.links[index + 1])
                                                if (config.pages[workflowStarted]
                                                    ?.stepperSteps?.links.length > index + 1) {
                                                    dispatch(changeHomeState({ currentWorkflowStep: index + 1 }))
                                                }
                                            }
                                        }
                                        if (config.pages[workflowStarted]
                                            ?.stepperSteps?.links.length > currentWorkflowStep + 1) {
                                            dispatch(changeHomeState({ currentWorkflowStep: currentWorkflowStep + 1 }))
                                        }
                                    }
                                    setLatestSseResponse(null);
                                    setOpenQrPopup(false)
                                    setQrMessage("")
                                    setOpenAwaitingCredentialAcceptance(false)
                                    setOpenForm(false)
                                }
                                } /> : (
                                <Grid container direction="column" justifyContent="space-between" alignItems="center"
                                    sx={{ minHeight: '10vh' }}>

                                    <Progress
                                        company={company}
                                        flowProgress={{
                                            type: types.WORKFLOW_PAGE,
                                            steps: flowProgress.steps
                                        }}
                                        latestSseResponse={latestSseResponse?.event}
                                        activeStep={flowProgress?.steps?.findLastIndex((x) => x.state === latestSseResponse?.event) + 1}
                                    />
                                    <CircularProgress />
                                </Grid>
                            )}
                    </CustomDialog> : null}
                </Grid>
            </Card></Box>
    )
}
