import { ArrowForward } from '@mui/icons-material';
import { Button, CircularProgress, Drawer, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useRef, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { v4 } from "uuid";
import { sendCredential } from '../../api/calls';
import trentialBackground from "../../assets/svg/uniqueCredentialPageBackground.svg";
import MarkdownWrapper from '../../components/common/markdownWrapper';
import Applications from '../../components/credential/cards/application';
import Form from '../../components/credential/form';
import FormForCitizenId from '../../components/credential/formForCitizenId';
import useProgressSSE from '../../components/credential/progressSse';
import QRPopup from '../../components/credential/qrPopup';
import TransparentHeader from '../../components/credential/transparentHeader';
import CustomDialogForUnique from '../../components/customDialog/forUnique';
import { config, types } from '../../config/config';
import { changeHomeState } from '../home/slice';


const mapKeys = {
    'name': "Full Name",
    'dob': "Year of Birth",
    'address': "Address",
    'gender': "Gender",
}

export default function UniqueCredentialPage({ company, onButtonClick }) {

    document.title = (config.pages[company]?.title ? (config.pages[company]?.title + " | ") : '')
        + "Get Credential |" + config.title

    const dispatch = useDispatch();
    const history = useHistory()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const backdropContainerRef = useRef(null)
    const [invitationUrl, setInvitationUrl] = useState(null)
    const [openQrPopup, setOpenQrPopup] = useState(false)
    const [sseExists, setSseExists] = useState(false)
    const [uuid, setUuid] = useState(false)
    const [latestSseResponse, setLatestSseResponse] = useState(null)
    const [checkIfQRClosedManually, setCheckIfQRClosedManually] = useState(false);
    const [openBottomDrawer, setOpenBottomDrawer] = useState(false);
    const [formInputData, setFormInputData] = useState({ name: '', dob: '', address: '', gender: '' });

    const cur = config.pages[company]?.flowScreen ?? {}
    const flowProgress = config.pages[company]?.flowProgress ?? {}

    const steps = flowProgress.type !== types.FORM ? flowProgress?.steps : flowProgress?.formSteps;
    const { currentWorkflowStep, workflowStarted } = useSelector(s => s.home)



    const progressSSE = useProgressSSE({
        page: config.pages[company]?.credentialInviteId,
        checkIfQRClosedManually: checkIfQRClosedManually,
        setCheckIfQRClosedManually: setCheckIfQRClosedManually,
        setInvitationUrl: setInvitationUrl,
        setOpenQrPopup: setOpenQrPopup,
        openQrPopup: openQrPopup,
        setLatestSseResponse: setLatestSseResponse,
        setUuid: setUuid,
        disableQrDialogOnThisState: flowProgress.disableQrDialogOnThisState,
        endFlowOnThisState: flowProgress.endFlowOnThisState,
        shouldTrigger: false,
    })

    const onSubmitForm = async () => {
        const tempUuid = v4()
        return sendCredential({
            attributes: Object.keys(formInputData)
                .map(key => ({
                    name: mapKeys[key],
                    value: formInputData[key]
                })),
            template: 'citizen-id',
            uid: tempUuid
        }, 'issue').then(res => {
            if (res) {
                progressSSE.establishSSE(tempUuid)
                setInvitationUrl(res)
                setSseExists(true)
                setOpenQrPopup('showQr')
                setUuid(tempUuid)
            }
        });
    }

   
    console.log("openQrPopup::", openQrPopup)

    if (company) {
        return (
            <>
                <Grid container
                    ref={backdropContainerRef}
                    direction="column"
                    justifyContent={"flex-start"}
                    alignItems="center"
                    sx={{
                        minHeight: '100vh', backgroundImage: 'url("' + trentialBackground + '")',
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: 'center', /* Center the background image in the container */
                        overflow: 'hidden' /* Hide the overflowing parts of the image */

                    }}
                >


                    <TransparentHeader company={company} />

                    <Grid container direction="column" justifyContent={"flex-start"} alignItems="flex-start"
                        sx={{ width: { xs: '98%', md: '80%', lg: '80%' }, padding: '1em', margin: 'auto' }}>

                        {cur?.back ? <Button variant="text" onClick={() => history.push(cur?.back.link)}
                            sx={{
                                marginRight: 'auto',
                                color: 'grey',
                                // fontWeight: 'bold',
                                fontSize: '1.5rem',
                                marginBottom: '1em'
                            }}
                        ><b>{"< "}&nbsp;</b>{cur?.back?.title ?? ""}</Button> : null}

                        <Grid container alignItems="center"
                            sx={{
                                flexDirection: { xs: 'column-reverse', md: 'row' },
                                justifyContent: { xs: 'center', md: 'flex-start' }
                            }}>
                            <Grid item xs={12} sm={12} md={7} lg={6}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: { xs: 'center', md: 'flex-start' },
                                    textAlign: { xs: 'center', md: 'left' }
                                }}>
                                <Typography variant="body1"
                                    sx={{
                                        fontSize: { xs: '6vw', sm: '4vw', md: '4rem' },
                                        fontWeight: 600,
                                        lineHeight: 1.2,
                                        color: '#ffffff',
                                        marginBottom: '0.15em'
                                    }}>
                                    <MarkdownWrapper>{cur.title}</MarkdownWrapper></Typography>
                                {/* <div style={{ backgroundColor: config.pages[company]?.theme, height: '5px', width: "80px", margin: '1em 0' }}></div> */}
                                <Typography variant="body1" sx={{
                                    fontSize: { xs: '3vw', sm: '2vw', md: '1.6rem' }, fontWeight: 400,
                                    color: '#ffffff',
                                    //   color: config.pages[company]?.themeSecondary,
                                }}>{cur.subtitle}</Typography>

                                <Grid container direction="row"
                                    justifyContent={"flex-start"}
                                    alignItems={"center"}
                                    sx={{
                                        flexDirection: { xs: 'column', md: 'row' },
                                        justifyContent: { xs: 'center', md: 'flex-start' }
                                    }}>
                                    <Button variant="contained"
                                        // disabled={latestSseResponse}
                                        onClick={() => {

                                            // dispatch(manageConfetti(true))
                                            if (cur?.startFlowButton.link) {
                                                history.push(cur?.startFlowButton.link)
                                            }
                                            else {
                                                setOpenQrPopup('startQrForm')
                                            }

                                        }}
                                        sx={{
                                            minWidth: '200px',
                                            // display: { xs: 'none', md: 'flex' },
                                            marginLeft: { xs: '1em', md: '0' },
                                            // backgroundColor: 'transparent',
                                            padding: '1em 1.5em',
                                            // margin: '1em 0',
                                            // fontWeight: 600,
                                            // fontSize: '1.8rem',
                                            // borderRadius: '8px',
                                        }}
                                    >{cur?.startFlowButton.title ?? ""}
                                        <ArrowForward style={{ fontSize: '2em' }} /></Button>

                                    <Button variant="outlined"
                                        // disabled={latestSseResponse}
                                        onClick={() => {
                                            setOpenBottomDrawer(true)
                                        }}
                                        sx={{
                                            minWidth: '200px',
                                            // display: { xs: 'none', md: 'flex' },
                                            // marginRight: 'auto',
                                            // backgroundColor: cur?.startFlowButton.color,
                                            padding: '1.1em 1.5em',
                                            // margin: '1em 0',
                                            // fontWeight: 600,
                                            // fontSize: '1.8rem',
                                            // borderRadius: '8px',
                                            backgroundColor: 'transparent',
                                            borderColor: '#47F4C7 !important',
                                            color: '#47F4C7',
                                            '&:hover': {
                                                backgroundColor: '#4D7284',
                                                color: '#47F4C7',
                                            }
                                        }}
                                    >{cur?.alreadyHaveCredentialButton.title ?? ""}</Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={6}
                                sx={{ textAlign: 'right' }}>
                                {cur.image && <img src={process.env.PUBLIC_URL + "images" + cur.image} alt="hero"
                                    style={{
                                        height: matches ? '50vw' : '38vw',
                                        margin: '0', float: 'right', marginLeft: '0'
                                    }} />}
                            </Grid>

                        </Grid>

                        {(flowProgress.type === types.FORM &&
                            (latestSseResponse?.event)) ? <Form
                            latestSseResponse={latestSseResponse}
                            flowProgress={flowProgress}
                            setLatestSseResponse={setLatestSseResponse}
                            setOpenQrPopup={setOpenQrPopup}
                            page={config.pages[company]?.credentialInviteId}
                            uuid={uuid}
                            setInvitationUrl={setInvitationUrl}
                        /> : null}

                        <Drawer
                            anchor={'bottom'}
                            open={openBottomDrawer}
                            onClose={() => setOpenBottomDrawer(false)}
                            sx={{
                                '& .MuiPaper-root': {
                                    ...((!isDesktop ||
                                        cur?.action?.applications.length > 3) ? { top: '200px' } : {})
                                }
                            }}
                        >
                            {cur?.action?.applications?.length > 0 ? <Grid
                                container direction="column" justifyContent={"flex-start"} alignItems="flex-start">
                                {/* <Typography variant="body1" sx={{ fontSize: '1.8rem', fontWeight: 600 }}>{cur.action.title}</Typography>
                            <Typography variant="body2" sx={{ fontSize: '1.6rem', fontWeight: 500 }}>{cur.action.subtitle}</Typography> */}
                                <Grid container alignItems="stretch" justifyContent={"space-around"} spacing={2}
                                    sx={{ margin: matches ? 0 : '1em -14px', padding: 0, width: '100%' }}>
                                    {cur?.action?.applications?.map((application, index) => (
                                        <Applications
                                            key={"application-" + index}
                                            index={index}
                                            onButtonClick={onButtonClick}
                                            application={application}
                                            company={company} />))}
                                </Grid>
                            </Grid> : null}
                        </Drawer>


                    </Grid>

                    {/* <ProgressSSE page={config.pages[company]?.credentialInviteId}
                        checkIfQRClosedManually={checkIfQRClosedManually}
                        setCheckIfQRClosedManually={setCheckIfQRClosedManually}
                        setInvitationUrl={setInvitationUrl}
                        setOpenQrPopup={setOpenQrPopup}
                        openQrPopup={openQrPopup}
                        setLatestSseResponse={setLatestSseResponse}
                        setUuid={setUuid}
                        disableQrDialogOnThisState={flowProgress.disableQrDialogOnThisState}
                        endFlowOnThisState={flowProgress.endFlowOnThisState}
                        sseExists={sseExists}
                    /> */}

                    {openQrPopup ? <CustomDialogForUnique
                        // backdropContainerRef={backdropContainerRef}
                        title={openQrPopup === 'success' ? '' : (latestSseResponse ? flowProgress.title
                            : openQrPopup === 'showQr' ? (flowProgress.qrCodeScannerTitle
                                ?? "Scan the QR code from Trential App to connect") : '')}
                        open={openQrPopup}
                        setOpen={(e) => {
                            setOpenQrPopup(e);
                            if (!e) {
                                setLatestSseResponse(null);
                                setInvitationUrl(null)
                            }
                        }}
                        fullWidth={true}
                        maxWidth={"md"}
                        actionTriggers={[{
                            buttonText: 'Next Step',
                            disabled: !(steps?.findLastIndex((x) => x
                                .state === latestSseResponse?.event) === (steps?.length - 1)),
                            onClick: () => {
                                // console.log('HIIIIIIIIIIIII', workflowStarted, config.pages[workflowStarted]
                                //     ?.stepperSteps?.links?.includes('/' + company), company)
                                if (config.pages[company]?.congratulationsScreen
                                    || config.pages[company]?.flowScreen.action?.applications?.length > 0) {
                                    setOpenQrPopup(false)
                                    setOpenBottomDrawer(true)
                                } else {
                                    if (workflowStarted && config.pages[workflowStarted]
                                        ?.stepperSteps?.links?.includes('/' + company)) {


                                        if (currentWorkflowStep === config.pages[workflowStarted].stepperSteps.steps.length - 1) {
                                            history.push({ pathname: workflowStarted, state: { success: true } })
                                        }

                                        let index = config.pages[workflowStarted]
                                            ?.stepperSteps?.links?.findLastIndex(x => x === ('/' + company))

                                        if (config.pages[workflowStarted]
                                            ?.stepperSteps?.links[index + 1]) {
                                            history.push(config.pages[workflowStarted]
                                                ?.stepperSteps?.links[index + 1])
                                        }
                                        // console.log('HIIIIIIIII', index, config.pages[workflowStarted]
                                        //     ?.stepperSteps?.links[index + 1])
                                        if (config.pages[workflowStarted]
                                            ?.stepperSteps?.links.length > index + 1) {
                                            dispatch(changeHomeState({ currentWorkflowStep: index + 1 }))
                                        }
                                    }
                                    setOpenQrPopup(false)
                                }
                                // history.goBack();
                                setLatestSseResponse(null)
                                setInvitationUrl(null)
                                setOpenQrPopup(false)
                            }
                        }]}
                    >
                        {openQrPopup !== 'success' ? <QRPopup
                            setInvitationUrl={setInvitationUrl}
                            // setOpenQrPopup={setOpenQrPopup}
                            setOpenBottomDrawer={setOpenBottomDrawer}
                            invitationUrl={invitationUrl}
                            latestSseResponse={latestSseResponse}
                            setLatestSseResponse={setLatestSseResponse}
                            flowProgress={flowProgress}
                            company={company} /> : openQrPopup === 'startQrForm' ? <FormForCitizenId
                                formInputData={formInputData}
                                setFormInputData={setFormInputData}
                                setOpenQrPopup={setOpenQrPopup}
                            /> : null}
                    </CustomDialogForUnique> : null}

                </Grid >

                {/* <Footer color="transparent" /> */}
            </>
        )
    } else {
        <Grid container direction="column" justifyContent="center" alignItems="center">
            <CircularProgress />
        </Grid>
    }
}


