import { Backdrop, Button, Dialog, Divider, Grid, Typography } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { useEffect, useState } from "react";
import trentialBackground from '../../assets/svg/trentialBackground.svg';

export default function CustomDialogForUnique({ title, open, setOpen, children,
  actionTriggers, backdropContainerRef = { current: null }, ...props }) {
  const [bboxBackdrop, setBboxBackdrop] = useState({})

  const handleClose = () => {
    setOpen(false);
  };

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleOpenBackdrop = () => {
    setOpenBackdrop(true);
  };

  const generateBackdropBbox = () => {
    const bbox = backdropContainerRef.current?.getBoundingClientRect() ?? {};
    console.log('bbox1', bbox)
    const x = JSON.parse(JSON.stringify(bbox))
    setBboxBackdrop(x)
  }

  useEffect(() => {
    handleOpenBackdrop(open)
    generateBackdropBbox()
  }, [open])



  return (
    <>
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        '& .MuiPaper-root': { borderRadius: '16px', ...props.containerStylesCustom },

      }}
      slots={{ backdrop: () => { <></> } }}
      {...props}
    >
      {/* <DialogTitle id="customized-dialog-title"
        sx={{
          fontSize: 'var(--body-font-size-xlarge)',
          fontWeight: 700,
          display:'flex',
          flexDirection: 'row',
          justifyContent:'space-between',
          alignItems: 'center'
        }} onClose={handleClose}>
        {title}
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              marginLeft: '1em',
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle> */}


      <DialogContent sx={{
        padding: '20px 24px',
        backgroundImage: `url(${trentialBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}>
        {title ? <Typography variant="h2" sx={{
          fontSize: '1.5rem',
          fontWeight: 700,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          margin: '1em',
          lineHeight: 'inherit'
        }}  >{title} </Typography> : null}
        {children}
      </DialogContent>

      <Divider variant="fullWidth" sx={{ backgroundColor: '#d5d5d5', width: '100%' }} />

      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        {actionTriggers.length > 0 && actionTriggers.map((x, i) => <Button
          key={x.buttonText + "-" + i}
          variant="contained"
          disabled={x.disabled}
          sx={{ float: 'right', marginLeft: 'auto' }}
          onClick={x.onClick}>
          {x.buttonText}
        </Button>)}</Grid>

      {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions> */}
    </Dialog>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'absolute',
          ...bboxBackdrop
        }}
        open={openBackdrop}
        onClick={handleCloseBackdrop}
      /></>
  );
}
