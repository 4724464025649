import { InfoOutlined } from "@mui/icons-material";
import { CircularProgress, Grid, Skeleton, Step, StepConnector, StepLabel, Stepper, Tooltip, Typography, stepConnectorClasses } from "@mui/material";
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import QRCode from 'qrcode.react';
import { useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import { useDispatch } from "react-redux";
import checkInGreenCircle from '../../assets/svg/checkInGreenCircle.svg';
import clock3 from '../../assets/svg/clock3.svg';
import questionMarkInYellowCircle from '../../assets/svg/questionMarkInYellowCircle.svg';
import { config, types } from "../../config/config";
import { manageConfetti } from "../../containers/home/slice";
import MarkdownWrapper from "../common/markdownWrapper";

const QRPopup = (props) => {
    const { latestSseResponse, flowProgress,
        setOpenQrPopup, company, qrMessage } = props;
    const steps = flowProgress.type !== types.FORM ? flowProgress?.steps : flowProgress?.formSteps;



    let invitation_url = props.invitationUrl ?? null;
    console.log('invitation_url', invitation_url)
    return isDesktop ? (
        <Grid container direction="row"
            alignItems="flex-start" sx={{
                justifyContent: {
                    xs: 'center',
                    md: (flowProgress && flowProgress.type !== types.FORM) ? "space-between" : "center"
                },
                flexWrap: { xs: 'wrap', md: 'nowrap' },
            }}>
            {/* <span style={{paddingLeft:'100%'}}><i className="fa fa-close w3-xlarge"></i></span> */}



            <Grid sx={{
                margin: 'auto', display: 'flex', flexDirection: 'column',
                justifyContent: 'center', alignItems: 'center',
                backgroundColor: "#ffffff",
            }}>
                {!invitation_url ? (
                    <Skeleton variant="rectangular" width={220} height={220} sx={{ margin: '1em' }} />
                ) : (
                    <QRCode
                        id={invitation_url}
                        value={invitation_url}
                        size={240}
                        level={"L"}
                        includeMargin={true}
                    />
                )}
                {/* <ol style={{textAlign:'left'}}>
                    <li>Open Trential app on your phone </li>
                    <li>Tap Scan Code </li>
                    <li>Scan the QR code from Trential app. </li>
                    <li>Wait for some time while you receive the Proof Request</li>
                </ol> */}

                <Typography variant="h2" sx={{ lineHeight: 'inherit' }}>{qrMessage}</Typography>
            </Grid>
            {(flowProgress) ? (
                <Grid>
                    <Typography variant="body1"
                        sx={{ fontWeight: 600, fontSize: '1.4rem' }}>
                        {flowProgress.subtitle}
                    </Typography>
                    <Progress
                        company={company}
                        flowProgress={flowProgress}
                        latestSseResponse={latestSseResponse?.event}
                        setOpenQrPopup={setOpenQrPopup}
                        activeStep={steps?.findLastIndex((x) => x.state === latestSseResponse?.event) + 1}
                    />

                    {/* <Button
                        variant="contained"
                        disabled={!(steps?.findLastIndex((x) => x
                            .state === latestSseResponse?.event) === (steps?.length - 1))}
                        sx={{ float: 'right' }}
                        onClick={() => {
                            // console.log('HIIIIIIIIIIIII', workflowStarted, config.pages[workflowStarted]
                            //     ?.stepperSteps?.links?.includes('/' + company), company)
                            if (config.pages[company]?.congratulationsScreen
                                || config.pages[company]?.flowScreen.action?.applications?.length > 0) {
                                setOpenQrPopup('success')
                                setOpenBottomDrawer(true)
                            } else {
                                if (workflowStarted && config.pages[workflowStarted]
                                    ?.stepperSteps?.links?.includes('/' + company)) {


                                    if (currentWorkflowStep === config.pages[workflowStarted].stepperSteps.steps.length - 1) {
                                        history.push({ pathname: workflowStarted, state: { success: true } })
                                    }

                                    let index = config.pages[workflowStarted]
                                        ?.stepperSteps?.links?.findLastIndex(x => x === ('/' + company))

                                    if (config.pages[workflowStarted]
                                        ?.stepperSteps?.links[index + 1]) {
                                        history.push(config.pages[workflowStarted]
                                            ?.stepperSteps?.links[index + 1])
                                    }
                                    // console.log('HIIIIIIIII', index, config.pages[workflowStarted]
                                    //     ?.stepperSteps?.links[index + 1])
                                    dispatch(changeHomeState({ currentWorkflowStep: index + 1 }))
                                }
                                setOpenQrPopup(false)
                            }
                            // history.goBack();
                            setLatestSseResponse(null)
                            setInvitationUrl(null)
                        }}>
                        Continue
                    </Button> */}
                </Grid>
            ) : null}
        </Grid>
    ) : (<Grid
        container
        direction="column"
        alignItems="center"
        sx={{
            justifyContent: 'center',
        }}>
        <Typography variant="body1" >
            Opening Trential Wallet App...
        </Typography>
        <br />
        <CircularProgress />
    </Grid>);
};



export function Progress({ latestSseResponse, setOpenQrPopup = () => { }, flowProgress, activeStep, company }) {
    const steps = flowProgress.type !== types.FORM ? flowProgress?.steps : flowProgress?.formSteps;
    const dispatch = useDispatch();
    const [stepCountPersist, setStepCountPersist] = useState(0)
    useEffect(() => {
        if (activeStep > stepCountPersist) {
            setStepCountPersist(activeStep)
        }
        if ((flowProgress?.steps?.length === activeStep) && (flowProgress.type !== types.FORM)) {

            dispatch(manageConfetti(true));
            setOpenQrPopup('success')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep])
    //console.log("steps", steps)
    return (
        <Stepper activeStep={stepCountPersist} orientation="vertical"
            connector={<QontoConnector />}
        >
            {steps?.map((step, index) => (
                <Step key={step.label}>
                    {/* <StepLabel>&nbsp;</StepLabel> */}
                    <StepLabel
                        sx={{
                            // display: 'flex', flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            // width: '100%',
                            '& .MuiCollapse-root': {
                                width: '100%',
                            },
                            '& .MuiStepLabel-iconContainer': {
                                marginTop: '-2.88px',
                            }
                        }}
                    ><Typography variant="body1"
                        color={'#000000'}
                        sx={{ fontSize: '1.2rem' }}>
                            <MarkdownWrapper>{step.label}</MarkdownWrapper>
                        </Typography>
                        {step?.info ? <Grid container direction="row" justifyContent="flex-start" alignItems="stretch"
                            sx={{
                                gap: '1em',
                                marginTop: '1em',
                            }}>
                            {step?.info?.map((text, index) => (<Grid
                                item
                                key={index}
                                sx={{
                                    background: flowProgress?.infoBorderColor ?? config.pages[company]?.themeShade,
                                    color: flowProgress?.infoColor ?? '#000000',
                                    // margin: '1em',
                                    padding: '1em',
                                    borderRadius: '8px',
                                    width: 'fit-content',
                                    margin: '0.1em 0 0',
                                }}>
                                <Typography variant="body1"
                                    sx={{
                                        fontSize: '1.1rem',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        fontWeight: 500
                                    }}>
                                    <InfoOutlined sx={{ marginRight: '5px' }} />
                                    {text}
                                </Typography>
                            </Grid>))}
                        </Grid> : null}
                        {step.infoInQuestionMarkIcon ? <Tooltip title={step.infoInQuestionMarkIcon} arrow>
                            <img src={questionMarkInYellowCircle} alt="clock" style={{ float: 'right' }} />
                        </Tooltip> : null}

                    </StepLabel>
                </Step>))}
        </Stepper>
    )
}

const QontoStepIconRoot = styled('div')(({ theme, ownerstate }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerstate.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {

    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerstate={{ active }} className={className}>
            {completed ? (
                <motion.img
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ type: "spring", stiffness: 100 }}
                    style={{ width: '18px' }}
                    src={checkInGreenCircle} alt="check" />
            ) : (
                <img src={clock3} alt="clock" style={{ width: '18px' }} />
            )}
        </QontoStepIconRoot>
    );
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({

    [`& .${stepConnectorClasses.line}`]: {
        minHeight: '0px',
        // border: 0,
        // backgroundColor:'red',
        // borderRadius: 1,
    },
}));



export default QRPopup;
