import { Grid, Typography } from '@mui/material'
import trentialLogoWithText from '../../assets/svg/trential-logo-text.svg'

export default function Footer({ color }) {
  return (
    <Grid
      container
      sx={{
        margin: 0,
        padding: '1em',
        // backgroundColor: 'white',
        // height: '6vh',
        // position: 'fixed',
        bottom: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: { xs: 'center', sm: 'space-between' },
        alignItems: 'center',
      }}
    >
      <Grid
        item
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography variant="body2" sx={{ display: 'inline-flex', alignItems: 'left', color: '#707070' }}>
          Powered by
        </Typography>
        {/* <Typography variant="body2" sx={{ display: 'inline-flex', alignItems: 'left', marginLeft: '1rem' }}>
          <img src={tretialFooterText} alt="logo" style={{ height: '17px' }} />
        </Typography> */}
        <img src={trentialLogoWithText} alt="logo" style={{ height: '30px', margin: '0.4em' }} />
      </Grid>
      <Grid
        item
        sx={{
          margin: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Grid item sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Typography component="a" variant="body2"
            sx={{ color: 'var(--main-color)', textDecoration: 'none', fontSize: { xs: '3vw', sm: '1rem' } }}
            href={"tel:+91-9149338300"}>+91 91493 38300 </Typography>
          <span style={{ color: '#888888', margin: '1em' }}>|</span>
          <Typography component="a" variant="body2"
            sx={{ color: 'var(--main-color)', textDecoration: 'none', fontSize: { xs: '3vw', sm: '1rem' } }}
            href={"mailto:info@trential.com"}> info@trential.com </Typography>
          <span style={{ color: '#888888', margin: '1em' }}>|</span>
          <Typography component="a" variant="body2"
            sx={{ color: 'var(--main-color)', textDecoration: 'none', fontSize: { xs: '3vw', sm: '1rem' } }}
            href={"https://www.trential.com"} target="_blank">www.trential.com</Typography>
        </Grid>

      </Grid>

    </Grid>
  )
}
