import { ArrowForward } from '@mui/icons-material';
import { Box, Button, Card, CircularProgress, Grid, Step, StepConnector, StepLabel, Stepper, Typography, stepConnectorClasses, styled } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import MarkdownWrapper from '../../components/common/markdownWrapper';
import Footer from '../../components/footer';
import CardContent from '../../components/landing/cardContent';
import { config } from '../../config/config';


import { motion } from 'framer-motion';
import { routes } from '../../api/routes';

const removeBoxShadow = (bool) => {
  return bool ? {
    boxShadow: 'none',
    paddingTop: '4em !important'
  } : {}
}
const showDashedBorderCss = (bool) => {
  return bool ? {
    borderTop: '1px dashed #343D48',
    borderRadius: 0,
    boxShadow: 'none',
    paddingTop: '4em !important'
  } : {}
}

const variantsUl = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const getFromArray = (ar, item) => {
  try {
    return ar[item]
  } catch (e) {
    return ar
  }
}

const URL = `${process.env.REACT_APP_BASE_URL}` + routes.eventStream;

export default function LandingPage({ company, isWorkflowPage, onButtonClick }) {
  document.title = (config.pages[company]?.title ? (config.pages[company]?.title + " | ") : '')
    + config.title
  // const backgroundCssRef = useRef(null)
  // const [firstCardCenterPoint, setFirstCardCenterPoint] = useState([0])
  const [showSignup, setShowSignup] = useState(false)
  const [payloadValue, setPayloadValue] = useState("")
  const [openQrCode, setOpenQrCode] = useState(false);
  const { currentWorkflowStep } = useSelector(s => s.home)
  const cur = config.pages[company]?.welcomeScreen ?? {}

  const title = isWorkflowPage ? getFromArray(cur?.title, currentWorkflowStep) : cur?.title
  const subtitle = isWorkflowPage ? getFromArray(cur?.subtitle, currentWorkflowStep) : cur?.subtitle
  const heroButtons = (isWorkflowPage ? getFromArray(cur?.heroButtons, currentWorkflowStep) : cur?.heroButtons)
  const heroImage = isWorkflowPage ? getFromArray(cur?.heroImage, currentWorkflowStep) : cur?.heroImage

  const StepIcon = ({ active, icon, completed, className }) => {
    return (<div className={className} style={{
      border: completed ? 0 : '2px solid ' + config.pages[company]?.theme,
      borderRadius: '50%', padding: completed ? '8px 10px' : '8px 10px',
      backgroundColor: completed ? config.pages[company]?.theme : '#ffffff',
      color: completed ? '#ffffff' : '#000000'

    }}>
      <span style={{ margin: '2px' }}>{icon}</span>
    </div>)
  }

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderBottom: '4px solid ' + config.pages[company]?.theme,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      border: 'none',
      borderBottom: '4px dashed #909090',
      width: '80%',
      margin: 'auto',
    },
  }));

  // useEffect(() => {
  //   const fn = () => {
  //     if (backgroundCssRef?.current) {
  //       const bbox = backgroundCssRef?.current.getBoundingClientRect();
  //       let body = document.body,
  //         html = document.documentElement;

  //       let height = Math.max(body.scrollHeight, body.offsetHeight,
  //         html.clientHeight, html.scrollHeight, html.offsetHeight);
  //       const val = (((((bbox.bottom - bbox.top) / 2) + bbox.top) / height) * 100) ?? 50
  //       setFirstCardCenterPoint([val, height])
  //     }
  //   }
  //   fn();
  //   setTimeout(() => {
  //     fn();
  //   }, 2000);
  //   window.onresize = fn;

  // }, [company])



  const MotionedCard = ({ children, ...props }) => {
    return <Card
      component={motion.li}
      // variants={variantsLi}
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.3,
        ease: [0, 0.71, 0.2, 1.01],
        scale: {
          type: "spring",
          damping: 8,
          stiffness: 50,
          restDelta: 0.001
        }
      }}
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // whileHover={{ scale: 1.1 }}
      // whileTap={{ scale: 0.95 }}
      {...props}
    >{children}</Card>
  }

  if (company) {
    return (
      <>
        <div style={{
          // background: firstCardCenterPoint[0] ? 'linear-gradient(to bottom, white 0%, white ' +
          //   firstCardCenterPoint[0]
          //   + '%, ' + config.pages[company]?.themeShade + ' '
          //   + firstCardCenterPoint[0] + '%, ' + config.pages[company]?.themeShade + ' 100%)' : 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          minHeight: '94.5vh'
        }}>

          <Grid container
            direction="column"
            justifyContent={"center"}
            alignItems="flex-start"
            component={motion.ul}
            variants={variantsUl}
            sx={{
              width: { xs: '98%', sm: '98%', md: '80%', lg: '70%' },
              padding: '1em',
              paddingBottom: '100px'
            }}>

            {cur?.back ? <Button variant="text" onClick={() => onButtonClick(cur?.back.link)}
              sx={{
                marginRight: 'auto',
                color: 'grey',
                // fontWeight: 'bold',
                fontSize: '1.5rem',
                marginBottom: '1em'
              }}
            ><b>{"< "}&nbsp;</b>{cur?.back.title ?? ""}</Button> : null}

            <Grid container justifyContent={"flex-start"} alignItems="flex-start"
              sx={{ flexDirection: 'row', margin: '2em 0' }}>
              <Grid item xs={12} sm={12} md={12} lg={heroImage ? 8 : 12} sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: { xs: 'center', sm: 'center', md: 'center', lg: 'flex-start' },
                alignItems: { xs: 'center', sm: 'center', md: 'center', lg: 'flex-start' },
              }}>
                {/* #hero title, subtitle, button */}
                <Typography variant="body1" sx={{
                  fontSize: '4rem', fontWeight: 600, lineHeight: 1.2,
                  marginBottom: '1em',
                  color: config.pages[company]?.theme ?? '#343D48'
                }}><MarkdownWrapper>1{title}</MarkdownWrapper></Typography>

                <Typography variant="body2" sx={{
                  fontSize: '1.8rem', fontWeight: 500,
                  color: config.pages[company]?.theme ?? '#343D48'
                }}><MarkdownWrapper>
                    {subtitle}</MarkdownWrapper></Typography>

                {heroImage ? <Grid
                  sx={{ display: { xs: 'inherit', sm: 'inherit', md: 'inherit', lg: 'none' } }}>
                  <img src={process.env.PUBLIC_URL + "images" + heroImage} alt="hero"
                    style={{ height: '20vh', margin: '1em', float: 'right', marginLeft: 'auto' }} />
                </Grid> : null}

                <Grid sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' },
                  justifyContent: { xs: 'center', sm: 'center', md: 'flex-start', lg: 'flex-start' },
                  alignItems: { xs: 'center', sm: 'center', md: 'flex-start', lg: 'flex-start' },
                  marginTop: '1em'
                }}>

                  {heroButtons?.length > 0 ? heroButtons.map((hb, i) => <Box
                    key={"hero-buttons" + i}>
                    <Typography variant="body2">{hb?.question ?? ""}</Typography>
                    <Button variant="contained" onClick={() => onButtonClick(hb.link)}
                      component={motion.div}
                      whileHover={{ scale: 1.1 }}
                      // whileTap={{ scale: 0.95 }}
                      sx={{
                        marginRight: 'auto',
                        margin: '0.2em 1em 1em 0',
                        // fontWeight: 'bold',
                        fontSize: '2rem',
                        minWidth: '150px',
                        padding: '0.2em 2em'
                      }}
                    >{hb.title ?? ""}
                      <ArrowForward style={{ fontSize: '1em' }} /></Button>
                  </Box>) : null}
                  {cur?.showPasswordLessLoginButton ? <Box>
                    <Typography variant="body2">{""}</Typography>
                    <Button variant="outlined" onClick={() => { }}
                      sx={{
                        marginRight: 'auto',
                        margin: '0.2em 1em 1em 0',
                        // fontWeight: 'bold',
                        fontSize: '2rem',
                        minWidth: '150px',
                        padding: '0.2em 2em'
                      }}
                    >Password-less Sign In
                      <ArrowForward style={{ fontSize: '1em' }} /></Button>
                  </Box> : null}
                </Grid>

              </Grid>
              {heroImage ? <Grid item xs={12} sm={12} md={5} lg={4}
                sx={{ display: { xs: 'none', lg: 'inherit' }, marginLeft: 'auto' }}>
                <img src={process.env.PUBLIC_URL + "images" + heroImage} alt="hero"
                  style={{ height: '20vh', margin: '1em', float: 'right', marginLeft: 'auto' }} />
              </Grid> : null}
            </Grid>

          </Grid>
          <Grid container
            direction="column"
            justifyContent={"center"}
            alignItems="center" sx={{
              width: '100%',
              backgroundColor: config.pages[company]?.themeShade,
            }}>
            <Grid container
              direction="column"
              justifyContent={"center"}
              alignItems="flex-start"
              component={motion.ul}
              variants={variantsUl}
              sx={{
                width: { xs: '98%', sm: '98%', md: '80%', lg: '70%' },
                marginTop: '-100px',
                padding: '1em'
              }}>
              {cur?.stepperSteps?.steps?.length > 0 ? <MotionedCard
                // ref={backgroundCssRef}
                raised
                sx={{ width: '100%', padding: '2em', borderRadius: '8px', margin: '2em 0' }}>
                <Stepper
                  activeStep={isWorkflowPage ? currentWorkflowStep : cur?.stepperSteps.stepsCompleted}
                  alternativeLabel
                  connector={<QontoConnector />}
                  sx={{
                    width: '100%'
                  }
                  }>
                  {cur?.stepperSteps.steps?.map((label, i) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={StepIcon}
                        sx={{
                          '&	.MuiStepLabel-label': {
                            color: config.pages[company]?.theme + " !important",
                            fontSize: '1.6rem',
                            fontWeight: 600 + " !important"
                          }
                        }}
                      >{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MotionedCard> : null}


              {cur?.sections?.length > 0 ? cur?.sections.map((section, i) => {

                const sectionToDisplay = (isWorkflowPage && section.stepDependent) ? section.items[currentWorkflowStep] : section;
                if (sectionToDisplay) {
                  return (<MotionedCard
                    // ref={cur?.stepperSteps?.steps?.length > 0 ? null : i === 0 ? backgroundCssRef : null}
                    key={"section-" + i} raised={Boolean(!section.disableCardLayout)}
                    sx={{
                      width: '100%', padding: '2em', borderRadius: '8px', margin: '2em 0',
                      ...showDashedBorderCss(Boolean(section.showDashedBorder)),
                      ...removeBoxShadow(Boolean(section.disableCardLayout))
                    }}>
                    <CardContent
                      company={company}
                      sectionToDisplay={sectionToDisplay}
                      isWorkflowPage={isWorkflowPage}
                      onButtonClick={onButtonClick} />
                  </MotionedCard>)
                }
                return null;
              }) : null}

            </Grid >
          </Grid>

        </div>
        <Footer color={config.pages[company]?.themeShade} />
        {/* {showSignup ? <PasswordLessSignIn
          payloadValue={payloadValue}
          openQrCode={openQrCode}
          setOpenQrCode={setOpenQrCode} /> : null} */}
      </>
    )
  } else {
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Grid>
  }
}
