import { Backdrop, Grid, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState, useLayoutEffect } from "react";
import trentialBackground from '../../assets/svg/trentialBackgroundSmall.svg';


export default function CustomDialog({ title,
  open, setOpen, children, backdrop = null,
  backdropContainerRef = { current: null }, ...props }) {

  const [bboxBackdrop, setBboxBackdrop] = useState({})

  const handleClose = () => {
    setOpen(false);
  };

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleOpenBackdrop = () => {
    setOpenBackdrop(true);
  };
  const generateBackdropBbox = () => {

    setTimeout(() => {
      const bbox = backdropContainerRef.current?.getBoundingClientRect() ?? {};
      const x = JSON.parse(JSON.stringify(bbox))
      setBboxBackdrop(x)
      setOpenBackdrop(open)
    }, 100);
  }

  useLayoutEffect(() => {
    generateBackdropBbox()
  }, [open])


  console.log('bboxBackdrop', bboxBackdrop)


  return (
    <><Dialog
      onClose={handleClose}
      open={Boolean(open)}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '16px',
          padding: '0',
          ...props.containerStylesCustom
        },

      }}
      slots={backdropContainerRef.current ?
        { backdrop: () => { <></> } }
        : {}}
      {...props}
    >
      {/* <DialogTitle id="customized-dialog-title"
        sx={{
          fontSize: 'var(--body-font-size-xlarge)',
          fontWeight: 700,
          display:'flex',
          flexDirection: 'row',
          justifyContent:'space-between',
          alignItems: 'center'
        }} onClose={handleClose}>
        {title}
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              marginLeft: '1em',
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle> */}
      <Grid
        sx={{
          width: '100%',
          border: title ? '1px solid #d5d5d5' : 'none',
          backgroundImage: `url(${trentialBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          marginBottom: '1em'
        }}>
        {title ? <Typography variant="h2" color="primary" sx={{
          fontSize: '1.5rem',
          fontWeight: 700,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          margin: '1em',
          lineHeight: 'inherit'
        }}  >{title} </Typography> : null}
      </Grid>

      <DialogContent sx={{
        padding: '1em',
      }}>
        {children}
      </DialogContent>
      {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions> */}
    </Dialog >
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'fixed',
          borderRadius: backdropContainerRef.current ? '20px' : 0,
          ...bboxBackdrop,
        }}
        open={openBackdrop}
        onClick={handleCloseBackdrop}
      /></>
  );
}
