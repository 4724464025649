
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import GenerateInput from '../common/generateInput';

export default function FormForCitizenId({ formInputData, setFormInputData, setOpenQrPopup }) {

    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const changeData = (field, value) => {
        const temp = { ...formInputData };
        temp[field] = value;
        setFormInputData(temp);
    }
    console.log('formInputData', formInputData);

    return (
        <Grid container directiion="column" justifyContent="flex-start" alignItems="flex-start"
            sx={{ padding: '1em' }}>
            <Typography variant="body1"
                sx={{
                    fontWeight: 'bold',
                    fontSize: '1.5rem',
                    margin: '0.5em 0 0'
                }}>
                Before continuing, please enter a few details.</Typography>
            <Grid container
                direction={matches ? "column" : "row"}
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{ padding: 0, margin: '1.2em 0 12vh' }}
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                <Grid item sx={{ width: { xs: "100%", md: '50%' } }}>
                    <GenerateInput
                        // disabled={checkIfDone(flowProgress?.formSteps.length - 1) || latestSseResponse?.payload?.findIndex(x => x
                        //     .name === input.attributeName) > -1}
                        inputData={{ label: 'Name', attributeName: 'name', type: 'text' }}
                        value={formInputData?.name}
                        onChange={(val) => {
                            changeData('name', val)
                        }}
                    />
                </Grid>
                <Grid item sx={{ width: { xs: "100%", md: '50%' } }}>
                    <GenerateInput
                        // disabled={checkIfDone(flowProgress?.formSteps.length - 1) || latestSseResponse?.payload?.findIndex(x => x
                        //     .name === input.attributeName) > -1}
                        // defaultDate="dd/mm/yyyy"
                        inputData={{ label: 'DOB', attributeName: 'dob', type: 'text' }}
                        value={formInputData?.dob}
                        onChange={(val) => {
                            changeData('dob', val)
                        }}
                    /></Grid>
                <Grid item sx={{ width: { xs: "100%", md: '50%' } }}>
                    <GenerateInput
                        // disabled={checkIfDone(flowProgress?.formSteps.length - 1) || latestSseResponse?.payload?.findIndex(x => x
                        //     .name === input.attributeName) > -1}
                        inputData={{ label: 'Address', attributeName: 'address', type: 'text' }}
                        value={formInputData?.address}
                        onChange={(val) => {
                            changeData('address', val)
                        }}
                    /></Grid>
                <Grid item sx={{ width: { xs: "100%", md: '50%' } }}>
                    <GenerateInput
                        select
                        options={[{ name: 'Male', value: 'Male' }, { name: 'Female', value: 'Female' }]}
                        // disabled={checkIfDone(flowProgress?.formSteps.length - 1) || latestSseResponse?.payload?.findIndex(x => x
                        //     .name === input.attributeName) > -1}
                        inputData={{ label: 'Gender', attributeName: 'gender', type: 'select' }}
                        value={formInputData?.gender}
                        onChange={(val) => {
                            changeData('gender', val)
                        }}
                    /></Grid>
            </Grid>

        </Grid >
    )
}
