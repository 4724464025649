import React, { Suspense } from 'react';

export default function DynamicComponentWrapper({ componentName, ...props }) {
  const MyLazyLoaded = React.lazy(() => import('../../components/customDesigns/' + componentName));
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <MyLazyLoaded {...props} />
    </Suspense>
  );

}