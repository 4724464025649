import { ArrowForward } from '@mui/icons-material';
import { Button, Card, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { config, types } from '../../../config/config';
import MarkdownWrapper from '../../common/markdownWrapper';


export default function Applications({ index, onButtonClick, application, company, xs = 12, sm = 6, md = 4, lg = 4 }) {
    const cur = config.pages[company]?.flowScreen ?? {}
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} key={"application-" + index}
            sx={{ display: 'flex', padding: '1em' }}>
            <Card
                raised
                sx={{
                    border: '1px solid #DADADA',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    backgroundColor: config.pages[company]?.themeShade ?? 'white',
                    padding: '0',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    // minHeight: '200px',
                    borderRadius: '16px'
                }}
            >
                <Grid item sx={{ margin: '2em 1em 1em' }}>
                    <Typography variant="body1" color={"primary.dark"} sx={{
                        fontSize: '1.8rem',
                        fontWeight: 600, lineHeight: 1.2
                    }}><MarkdownWrapper>{application.title}</MarkdownWrapper></Typography>
                    <div style={{
                        backgroundColor: config.pages[company]?.theme,
                        height: '2px', width: "32px", margin: '0.6em 0 1em'
                    }}></div>
                </Grid>


                <Grid
                    component={Card}
                    outlined
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        backgroundColor: '#ffffff',
                        margin: 0,
                        padding: '1em',
                        borderTopLeftRadius: '16px',
                        borderTopRightRadius: '16px',
                        flex: '1 auto',
                        flexWrap: 'nowrap',
                        alignItems: 'left'
                    }}
                >
                    <img src={process.env.PUBLIC_URL + "images" + application.image} alt="card"
                        style={{ maxHeight: matches ? '20vw' : '10vw', width: 'fit-content', margin: '1em auto' }} />
                    <Grid item xs={12} sm={6}><Typography
                        variant="body2"
                        sx={{
                            fontSize: '1.4rem',
                            fontWeight: 500,
                            margin: '1em'
                        }}>
                        <MarkdownWrapper>
                            {application.description}
                        </MarkdownWrapper>
                    </Typography>
                    </Grid>

                    <Button variant="contained"
                        onClick={() => onButtonClick([types.START_WORKFLOW, application.link])}
                        sx={{
                            width: '100%',
                            // marginRight: 'auto',
                            margin: '0 !important',
                            // minWidth: 0,
                            backgroundColor: cur?.startFlowButton.color,
                            padding: '1em',
                            // fontWeight: 'bold',
                            borderRadius: application?.buttonText ? '30px' : '50%',
                        }}
                    >{
                            application?.buttonText ?
                                application?.buttonText : <ArrowForward
                                    style={{ fontSize: '2em', margin: 0 }} />
                        }
                    </Button>
                </Grid>
            </Card>
        </Grid>
    )
}
