import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { config } from '../../../config/config';
import MarkdownWrapper from '../../common/markdownWrapper';

import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { changeHomeState } from '../../../containers/home/slice';
import DynamicComponentWrapper from '../../common/dynamicComponentWrapper';

export default function Congratulations(props) {

    const { company, setOpenQrPopup, attributes, setLatestSseResponse } = props;
    const cur = config.pages[company]?.congratulationsScreen ?? {}

    const history = useHistory();
    const dispatch = useDispatch();
    const { currentWorkflowStep, workflowStarted } = useSelector(s => s.home)
    const id = "id";


    useEffect(() => {
        if (!config.pages[company]?.congratulationsScreen) {
            setOpenQrPopup(false)
        }
    }, [company])

    const getAttrs = () => {
        let acc = {}
        if (config.pages[company]?.congratulationsScreen?.attributes) {
            Object.keys(config.pages[company]?.congratulationsScreen?.attributes)?.forEach((item, i) => {
                //console.log('=======', attributes, config.pages[company]?.congratulationsScreen?.attributes, item)
                if (attributes) {
                    acc[item] = attributes[config.pages[company]?.congratulationsScreen?.attributes[item]]
                }

            })
        }
        return acc;
    }
    if (cur) {
        return (
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid
                    container
                    alignItems="center"
                    sx={{
                        flexWrap: 'nowrap',
                        flexDirection: { xs: 'column', md: cur.flexDirection ?? 'row' },
                        justifyContent: { xs: 'center', md: 'space-between' }
                    }}
                >
                    <Grid item xs={12} sm={8} md={8} lg={8} sx={{ margin: '1em' }}>
                        <Typography variant="body1" ><MarkdownWrapper>
                            {cur?.info}</MarkdownWrapper></Typography>
                    </Grid>
                    {cur.customImage ? <DynamicComponentWrapper
                        componentName={cur.customImage}
                        onClick={() => { }}
                        attrs={getAttrs()}
                    /> : (cur?.image ? <Grid
                        item
                        xs={12} sm={4} md={4} lg={4}>
                        <img src={process.env.PUBLIC_URL + "images"
                            + cur?.image}
                            alt="congratulations"
                            style={{ width: '100%' }} />
                    </Grid> : null)}

                </Grid>

                <Button variant="contained" color="primary"
                    sx={{ margin: { xs: 'auto', md: '1em' }, marginLeft: 'auto !important' }}
                    onClick={() => {
                        setLatestSseResponse(null)
                        if (props.onFinish) {
                            props.onFinish()
                        } else {
                            if (workflowStarted && config.pages[workflowStarted]
                                ?.stepperSteps?.links?.includes('/' + company)) {
                                let index = config.pages[workflowStarted]
                                    ?.stepperSteps?.links?.findLastIndex(x => x === ('/' + company))
                                if (currentWorkflowStep >= config.pages[workflowStarted]
                                    ?.stepperSteps?.links.length - 1) {
                                    history.push({ pathname: workflowStarted, state: { success: true } })
                                } else if (config.pages[workflowStarted]
                                    ?.stepperSteps?.links[index + 1]) {
                                    history.push(config.pages[workflowStarted]
                                        ?.stepperSteps?.links[index + 1])
                                }
                                dispatch(changeHomeState({
                                    currentWorkflowStep: (index >= config.pages[workflowStarted]
                                        ?.stepperSteps?.links.length - 1) ? config.pages[workflowStarted]
                                            ?.stepperSteps?.links.length : index + 1
                                }))
                            }
                        }

                        setOpenQrPopup(false)
                    }}>
                    {currentWorkflowStep >= config.pages[workflowStarted]
                        ?.stepperSteps?.links.length - 1 ? 'Finish' : 'Next'}
                </Button>
            </Grid >
        )
    } else {
        return <CircularProgress />
    }
}
