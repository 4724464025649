import { Button, Card, CircularProgress, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import DynamicComponent from '../../components/common/dynamicComponentWrapper';
import MarkdownWrapper from '../../components/common/markdownWrapper';
import Congratulations from '../../components/credential/cards/congratulations';
import Form from '../../components/credential/form';
import HeaderAppBar from '../../components/credential/header';
import useProgressSSE from '../../components/credential/progressSse';
import QRPopup from '../../components/credential/qrPopup';
import WorkflowStepper from '../../components/credential/workflowStepper';
import CustomDialog from '../../components/customDialog';
import { config, types } from '../../config/config';
import { changeHomeState, manageConfetti } from '../home/slice';

export default function CredentialPage({ company, onButtonClick }) {
  document.title = (config.pages[company]?.title ? (config.pages[company]?.title + " | ") : '')
    + "Get Credential |" + config.title

  const history = useHistory()
  const dispatch = useDispatch();
  const theme = useTheme()
  const backdropContainerRef = useRef(null)
  const [jargonRef, setJargonRef] = useState(null)
  const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { workflowStarted } = useSelector(s => s.home)
  const [openForm, setOpenForm] = useState(false)
  const [invitationUrl, setInvitationUrl] = useState(null)
  const [openQrPopup, setOpenQrPopup] = useState(false)
  const [qrMessage, setQrMessage] = useState(null)
  const [uuid, setUuid] = useState(false)
  const [latestSseResponse, setLatestSseResponse] = useState(null)
  const [checkIfQRClosedManually, setCheckIfQRClosedManually] = useState(false);
  const cur = config.pages[company]?.flowScreen ?? {}
  const flowProgress = config.pages[company]?.flowProgress ?? {}

  const activeWorkflow = config.pages[workflowStarted]
  //console.log('jargonRef.current', jargonRef, jargonRef?.getBoundingClientRect().bottom)


  const progressSSE = useProgressSSE({
    page: config.pages[company]?.credentialInviteId,
    checkIfQRClosedManually: checkIfQRClosedManually,
    setCheckIfQRClosedManually: setCheckIfQRClosedManually,
    setInvitationUrl: setInvitationUrl,
    setOpenQrPopup: setOpenQrPopup,
    openQrPopup: openQrPopup,
    setLatestSseResponse: setLatestSseResponse,
    setUuid: setUuid,
    disableQrDialogOnThisState: flowProgress.disableQrDialogOnThisState,
    onFinish: () => {
      dispatch(manageConfetti(true))
      if (config.pages[company]?.congratulationsScreen
        || config.pages[company]?.flowScreen.action?.applications?.length > 0) {
        setOpenQrPopup('success')
      } else {
        if (workflowStarted && config.pages[workflowStarted]
          ?.stepperSteps?.links?.includes('/' + company)) {
          let index = config.pages[workflowStarted]
            ?.stepperSteps?.links?.findLastIndex(x => x === ('/' + company))

          if (config.pages[workflowStarted]
            ?.stepperSteps?.links[index + 1]) {
            history.push(config.pages[workflowStarted]
              ?.stepperSteps?.links[index + 1])
          }
          if (config.pages[workflowStarted]
            ?.stepperSteps?.links.length > index + 1) {
            dispatch(changeHomeState({ currentWorkflowStep: index + 1 }))
          }
        }

        if (openForm) {
          setOpenQrPopup(false)
        } else {
          setOpenQrPopup('close')
        }
      }
      // history.goBack();
      setLatestSseResponse(null)
      setOpenForm(false)
    }
  })
  useEffect(() => {
    setOpenForm(false);
  }, [company])


  console.log('openQrPopup', openQrPopup)

  if (company) {
    return (
      <>
        <Grid container direction="column" justifyContent={"flex-start"} alignItems="center"
          sx={{
            minHeight: '100vh',
            background: matchesDownMd ? `linear-gradient( to bottom, #ffffff, #ffffff 80%,
              #EEF2FB 80%, #EEF2FB )`: '#ffffff'
          }}
        >
          {activeWorkflow ? <WorkflowStepper company={company} /> : null}

          <Grid ref={backdropContainerRef} container direction="column" justifyContent={"flex-start"} alignItems="center"
            sx={{
              width: matchesDownMd ? "100%" : "80%",
              // minHeight: '95vh',
              border: matchesDownMd ? "none" : '4px solid #000000',
              boxShadow: matchesDownMd ? "none" : '0px 4px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: matchesDownMd ? "0px" : '20px',
              margin: matchesDownMd ? 0 : 'auto',
              paddingBottom: '2em',
              backgroundColor: "#ffffff",
            }}
          >
            <HeaderAppBar company={company} />
            <Grid container direction="column" justifyContent={"flex-start"} alignItems="center"
              sx={{
                background: config.pages[company]?.themeShade ?? 'white',
                minHeight: '20vh',
                // borderBottomLeftRadius: matchesDownMd ? "0px" : '20px',
                // borderBottomRightRadius: matchesDownMd ? "0px" : '20px',
                // maxHeight: cur.customElements ? '40vh' : '65vh'
              }}>


              <Grid container direction="column"
                sx={{
                  // width: { xs: '98%', md: '80%', lg: '80%' },
                  justifyContent: { xs: 'center', md: 'flex-start' },
                  alignItems: { xs: 'center', md: 'flex-start' },
                  padding: '0',
                }}>
                {/* <Button onClick={() => history.push({ pathname: workflowStarted, state: { success: true } })}>Boom</Button> */}
                {/* BACK BUTTON */}
                {cur?.back?.title ? <Button variant="text" onClick={() => history.push(cur?.back.link)}
                  sx={{
                    marginRight: 'auto',
                    color: 'grey',
                    // fontWeight: 'bold',
                    fontSize: '1.5rem',
                    marginBottom: '1em'
                  }}
                ><b>{"< "}&nbsp;</b>{cur?.back.title ?? ""}</Button> : null}

                {/* IMAGE AND HERO CONTENT */}
                {<Grid container alignItems='center'
                  sx={{
                    flexDirection: { xs: 'column-reverse', md: 'row' },
                    justifyContent: { xs: 'center', md: 'flex-start' },
                    padding: 0
                    // margin: '1em'
                  }}>
                  {openForm && cur.customElements?.formTopScreen ?
                    (<DynamicComponent
                      componentName={cur.customElements.formTopScreen}
                      onClick={() => {
                        if (flowProgress.type === types.FORM) {
                          setOpenForm(true)
                        } else {
                          // dispatch(manageConfetti(true))
                          if (cur?.startFlowButton.link) {
                            history.push(cur?.startFlowButton.link)
                          }
                          else {
                            setOpenQrPopup('showQr')
                          }
                        }
                      }}
                    />) :
                    <>
                      <Grid ref={ref => setJargonRef(ref)}
                        item
                        xs={12}
                        sm={12}
                        md={openForm ? 12 : 7}
                        lg={openForm ? 12 : 6}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: { xs: 'center', md: 'flex-end' },
                          textAlign: { xs: 'center', md: 'left' },
                          minHeight: { xs: '15vw', md: '18vw' }
                        }}>
                        {
                          cur.customElements ?
                            <DynamicComponent
                              componentName={cur.customElements.welcomeScreen}
                              onClick={() => {
                                if (flowProgress.type === types.FORM) {
                                  setOpenForm(true)
                                } else {
                                  // dispatch(manageConfetti(true))
                                  if (cur?.startFlowButton.link) {
                                    history.push(cur?.startFlowButton.link)
                                  }
                                  else {
                                    setOpenQrPopup('showQr')
                                  }
                                }
                              }}
                            />
                            : (
                              !openForm ?
                                <Grid container
                                  direction='column'
                                  justifyContent={!matchesDownMd ? "flex-start" : "center"}
                                  alignItems={!matchesDownMd ? "flex-start" : "center"}
                                  sx={{ width: '86%' }}>
                                  <Typography
                                    variant="body1"
                                    color="primary"
                                    sx={{ fontSize: { xs: '6vw', sm: '4vw', md: '2.4rem' }, fontWeight: 600 }}>
                                    <MarkdownWrapper>
                                      {cur.title}
                                    </MarkdownWrapper>
                                  </Typography>

                                  {matchesDownMd ? <img src={process.env.PUBLIC_URL + "images" + cur.image} alt="hero"
                                    style={{
                                      height: matchesDownMd ? ('40vw') : ('25vw'),
                                      margin: 'auto'
                                    }} /> : null}


                                  {/* if form is not open */}
                                  {
                                    !openForm ?
                                      <>
                                        {cur.subtitle ? (<>
                                          <br />
                                          <Typography variant="body1" sx={{
                                            fontSize: { xs: '3vw', sm: '2vw', md: '1.6rem' }, fontWeight: 600,
                                            color: config.pages[company]?.themeSecondary,
                                          }}><MarkdownWrapper>{cur.subtitle}</MarkdownWrapper>
                                          </Typography>
                                        </>) : null}
                                        {cur.subtitle2 ? (<>
                                          <br />
                                          <Typography variant="body2" sx={{
                                            fontSize: { xs: '2.5vw', sm: '1.5vw', md: '1.2rem' }, fontWeight: 500,
                                            color: cur.subtitle2.color,
                                          }}><MarkdownWrapper>{cur.subtitle2.text}</MarkdownWrapper>
                                          </Typography></>) : null}


                                        {(!cur.customElements) ? <Button variant="contained"
                                          // disabled={latestSseResponse}
                                          onClick={() => {
                                            setLatestSseResponse(null)
                                            if (flowProgress.type === types.FORM) {
                                              setOpenForm(true)
                                            } else {
                                              // dispatch(manageConfetti(true))
                                              if (cur?.startFlowButton.link) {
                                                history.push(cur?.startFlowButton.link)
                                              }
                                              else {
                                                setOpenQrPopup('showQr')
                                              }
                                            }
                                          }}
                                          sx={{
                                            marginRight: 'auto',
                                            backgroundColor: cur?.startFlowButton.color,
                                            padding: '1em 4em',
                                            margin: '1em 0',
                                            fontWeight: 600,
                                            fontSize: '1.2rem',
                                            // borderRadius: '4px',
                                            // minWidth: '20vw'
                                          }}
                                        >{cur?.startFlowButton.title ?? ""}
                                        </Button> : null}
                                      </>
                                      : null
                                  }

                                </Grid>
                                : cur.formImage ? <Grid container
                                  direction='column'
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  sx={{
                                    width: '100%',
                                    backgroundImage: `url(${process.env.PUBLIC_URL + "images" + cur.formImage})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    height: { xs: '15vw', md: '18vw' }
                                  }}>
                                </Grid> : null)
                        }

                      </Grid>
                      {!openForm ?
                        <Grid item xs={12} sm={12} md={openForm ? 4 : 5} lg={openForm ? 4 : 5}
                          sx={{
                            textAlign: 'right',
                            display: { xs: "none", md: 'block' },
                            marginLeft: 'auto',
                            background: 'transparent',
                          }}>
                          <img src={process.env.PUBLIC_URL + "images" + cur.image} alt="hero"
                            style={{
                              height: matchesDownMd ? (openForm ? '5vw' : '15vw') : (openForm ? "18vw" : '25vw'),
                              float: 'right', marginLeft: 'auto'
                            }} />
                        </Grid> : null}
                    </>}

                </Grid>}


              </Grid>
            </Grid>
            {!openForm && cur?.action?.applications?.length > 0 ? <Grid
              container direction="column" justifyContent={"flex-start"} alignItems="flex-start"
              sx={{ padding: '2em' }}>
              <Typography variant="body1" sx={{ fontSize: '1.8rem', fontWeight: 600, color: '#717171', textAlign: 'center', width: '100%' }}>{cur.action.title}</Typography>
              <Typography variant="body2" sx={{ fontSize: '1.6rem', fontWeight: 500, textAlign: 'center', width: '100%' }}>
                <MarkdownWrapper>{cur.action.subtitle}</MarkdownWrapper>
              </Typography>
              <Grid container alignItems="stretch" spacing={2} justifyContent={"space-around"}
                sx={{ margin: matchesDownMd ? 0 : '1em -14px', padding: 0, width: '100%' }}>
                {cur?.action?.applications?.map((application, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={"application-" + index}
                    sx={{ display: 'flex' }}>
                    <Card
                      raised
                      sx={{
                        backgroundColor: 'white',
                        padding: '1.2em',
                        width: '100%',
                        borderRadius: '12px',
                        ...(application?.accentColor ?
                          {
                            borderTop: '4px solid ' + application?.accentColor
                          } : {}),
                        minHeight: '150px',
                        cursor: 'pointer'
                      }}
                      onClick={() => history.push(application.link)}
                    >
                      <Typography variant="body1" sx={{ fontSize: '1.8rem', fontWeight: 600, lineHeight: 1.2 }}>{application.title}</Typography>
                      <div style={{ backgroundColor: config.pages[company]?.theme, height: '2px', width: "32px", margin: '0.6em 0 1em' }}></div>
                      <Typography variant="body2" sx={{ fontSize: '1.4rem', fontWeight: 500, color: '#808080' }}>{application.description}</Typography>
                      {/* <Button variant="contained" onClick={() => history.push(application.link)}
                            sx={{
                              marginRight: 'auto',
                              marginTop: '1em',
                              minWidth: 0,
                              backgroundColor: cur?.startFlowButton.color,
                              padding: 0,
                              // fontWeight: 'bold',
                              borderRadius: '50%',
                            }}
                          >
                            <ArrowForward style={{ fontSize: '2em', margin: 0 }} /></Button> */}
                    </Card>
                  </Grid>))}
              </Grid>
            </Grid> : null}


            {/* EXTRA WEB CONTENTS */}
            {(!openForm && cur?.jargon?.cards?.length > 0) ? <Grid
              container
              direction="column"
              justifyContent={"center"}
              alignItems="center"
              sx={(matchesDownMd) ? {
                marginTop: (cur?.jargon?.marginTop || !cur.customElements) ? (cur?.jargon?.marginTop ?? '1.4em')
                  : (jargonRef ? (matchesDownMd ? ((jargonRef?.getBoundingClientRect().bottom -
                    jargonRef?.getBoundingClientRect().top) * 0.06) :
                    (jargonRef?.getBoundingClientRect().bottom -
                      jargonRef?.getBoundingClientRect().top) * 0.04) : '4rem')
              } : { ...(cur.customElements ? { marginTop: '2em' } : {}) }}>
              <Typography variant="body1" color="primary"
                sx={{ fontSize: '1.8rem', fontWeight: 700 }}><MarkdownWrapper>{cur?.jargon.title}</MarkdownWrapper></Typography>
              {cur?.jargon.subtitle && <Typography variant="body2" sx={{ fontSize: '1.6rem', fontWeight: 500, color: '#808080' }}><MarkdownWrapper>{cur?.jargon.subtitle}</MarkdownWrapper></Typography>}
              <Grid container justifyContent="center" alignItems="stretch" spacing={2}
                sx={{ margin: matchesDownMd ? 0 : '1em -14px', padding: 0, width: '100%' }}>
                {cur?.jargon?.cards?.map((jargonCard, index) => (
                  <Grid item xs={11} sm={11} md={4} lg={3} key={"jargonCard-" + index}
                    sx={{ display: 'flex', paddingLeft: '0 !important', margin: '1em' }}>
                    <Card
                      raised
                      sx={{
                        // backgroundColor: config.pages[company]?.themeShade ?? 'white',
                        padding: '2.5em',
                        width: '100%',
                        // minHeight: '200px',
                        cursor: 'pointer',
                        border: "1px solid #DADADA",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        borderRadius: "16px",

                      }}
                      onClick={() => history.push(jargonCard.link)}
                    >
                      <Typography variant="body1" color="primary" sx={{
                        marginBottom: '1em',
                        fontSize: '1.8rem', fontWeight: 600, lineHeight: 1.2,
                        ...jargonCard?.titleCss
                      }}>
                        <MarkdownWrapper>{jargonCard.title}</MarkdownWrapper></Typography>
                      {/* <div style={{ backgroundColor: config.pages[company]?.theme, height: '2px', width: "32px", margin: '0.6em 0 1em' }}></div> */}
                      <Typography variant="body2" sx={{ fontSize: '1.4rem', fontWeight: 500, color: '#808080' }}>
                        <MarkdownWrapper>{jargonCard.subtitle}</MarkdownWrapper></Typography>
                      {/* <Button variant="contained" onClick={() => history.push(application.link)}
                        sx={{
                          marginRight: 'auto',
                          marginTop: '1em',
                          minWidth: 0,
                          backgroundColor: cur?.startFlowButton.color,
                          padding: 0,
                          // fontWeight: 'bold',
                          borderRadius: '50%',
                        }}
                      >
                        <ArrowForward style={{ fontSize: '2em', margin: 0 }} /></Button> */}
                    </Card>
                  </Grid>))}
              </Grid>
            </Grid> : null}



            {(config.pages[company]?.flowProgress?.type === types.FORM && openForm) ? <Form
              latestSseResponse={latestSseResponse}
              setQrMessage={setQrMessage}
              flowProgress={flowProgress}
              setLatestSseResponse={setLatestSseResponse}
              setOpenQrPopup={setOpenQrPopup}
              page={config.pages[company]?.credentialInviteId}
              uuid={uuid}
              company={company}
              setOpenForm={setOpenForm}
              setInvitationUrl={setInvitationUrl}
            /> : null}


            {/* QR DIALOG */}
            {openQrPopup ? <CustomDialog
              backdropContainerRef={backdropContainerRef}
              title={openQrPopup !== 'success' ?
                ((latestSseResponse && flowProgress.title) ? flowProgress.title : (flowProgress.qrCodeScannerTitle
                  ?? "Scan the QR code from Trential App to connect"))
                : config.pages[company]?.congratulationsScreen?.title}
              open={openQrPopup}
              setOpen={(e) => {
                setOpenQrPopup(e);
                if (!e) {
                  setLatestSseResponse(null);
                  setInvitationUrl(null)
                }
              }}
              fullWidth={true}
              maxWidth={(flowProgress && flowProgress.type !== types.FORM) ? (openQrPopup === 'success' ? 'sm' : "md") : "md"}
            // maxWidth="md"
            >
              {openQrPopup !== 'success' ? <QRPopup
                setInvitationUrl={setInvitationUrl}
                setOpenQrPopup={setOpenQrPopup}
                invitationUrl={invitationUrl}
                latestSseResponse={latestSseResponse}
                setLatestSseResponse={setLatestSseResponse}
                flowProgress={flowProgress}
                company={company}
                qrMessage={qrMessage} /> : (<Congratulations company={company} setOpenQrPopup={setOpenQrPopup}
                  setLatestSseResponse={setLatestSseResponse} />)}
            </CustomDialog> : null}

          </Grid>
        </Grid >

      </>
    )
  } else {
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Grid>
  }
}

